import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "../assets/img1.webp";
import img2 from "../assets/img2.webp";
import img4 from "../assets/img4.webp";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { SlCalender } from "react-icons/sl";

const HomeSlider = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });

    return () => {
      AOS.refresh();
    };
  }, []);

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="absolute top-[50%] right-4 hidden md:block text-white text-2xl cursor-pointer z-10"
        onClick={onClick}
      >
        <IoIosArrowForward />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="absolute top-[50%] left-4 hidden md:block text-white text-2xl cursor-pointer z-10"
        onClick={onClick}
      >
        <IoIosArrowBack />
      </div>
    );
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: () => {
      AOS.refresh();
    },
  };

  const slides = [
    {
      title: "Professional & Highly Trained Dental Staff",
      description:
        "Explore our comprehensive dental services from routine check-ups to advanced treatments",
      image: img1,
    },
    {
      title: "Quality Orthodontic Treatments for the entire family",
      description:
        "Meet our experienced team of dentists dedicated to your oral health.",
      image: img2,
    },
    {
      title: "Dental Service for the Entire Family",
      description:
        "Discover our state-of-the-art facilities designed for your comfort and care.",
      image: img4,
    },
  ];

  return (
    <div className="relative w-full h-[43rem] md:h-[40rem] lg:h-full -my-[4rem] mb-2 lg:mb-4">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div
            key={index}
            className="relative w-full h-[43rem] md:h-[40rem] lg:h-screen"
          >
            <img
              src={slide.image}
              alt={slide.title}
              className="absolute inset-0 w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black opacity-60" />
            <div className="absolute inset-0 flex flex-col items-start mt-[4rem] lg:mt-[7rem] p-4">
              <div className="flex flex-col gap-6 md:gap-10   items-center justify-center ml-4 md:ml-[7rem] animate-fade-in-down z-[999] max-w-5xl">
                <div className=" flex flex-col md:flex-row md:items-center  gap-6">
                  <h1 className="text-white shadow-sm p-2 text-sm md:text-lg rounded-md bg-[#abb8c36d] font-semibold">
                    <p className="opacity-[5] text-white">
                      Changing Lives One Smile At A Time!
                    </p>
                  </h1>
                  <p className="text-white shadow-sm w-fit p-2 text-sm md:text-lg rounded-md bg-primary-350 font-semibold">
                    Serving Since 2010
                  </p>
                </div>

                <h2 className="text-lg md:text-xl lg:text-4xl font-bold text-white heading-font">
                  {slide.title}
                </h2>

                <div className=" mx-auto mt-20">
                  <Link
                    to="/appointment"
                    className="bg-blue-500 duration-300 hover:bg-blue-600 text-white  py-2 px-4 rounded-3xl lg:mt-4 cursor-pointer w-fit text-sm md:text-lg flex items-center"
                  >
                    <SlCalender className="w-4 h-4 md:w-5 md:h-5 mr-2" />
                    Schedule an Appointment
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HomeSlider;
