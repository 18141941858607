import React from "react";
import ServiceCard from "./ServiceCard";
import crown from "../../Component/Pictures/crown.webp";

const Crown = () => {
  const content = {
    pagetitle: "Crown Bridge",
    imgUrl: crown,
    desc1:
      "A crown is a versatile dental restoration that encases a damaged or weakened tooth, restoring its strength, functionality, and aesthetics. Crafted from materials like porcelain, ceramic, or metal alloys, crowns are customized to match the color and shape of the natural teeth, ensuring a seamless blend with the rest of the smile. Whether protecting a tooth after a root canal treatment or restoring a severely decayed or fractured tooth, crowns provide durable and long-lasting solutions for patients seeking to preserve their dental health and enhance their smiles.",
    desc2:
      "A dental bridge is a fixed prosthetic device used to replace one or more missing teeth by bridging the gap between adjacent teeth. Consisting of one or more artificial teeth (pontics) anchored in place by dental crowns on either side, bridges restore proper chewing function, prevent the shifting of adjacent teeth, and help maintain the natural shape of the face. Dental bridges are meticulously crafted to match the color, size, and shape of the surrounding teeth, providing patients with a comfortable, functional, and aesthetically pleasing solution for restoring their smiles.",
    f1: "Durable and resilient materials used for crowns and bridges.",
    f2: "Natural look matching the color and shape of teeth.",
    f3: "Restoration of normal biting and chewing function.",
    section1: "Benefits of Crowns and Bridges",
    section1Content: [
      "Restoration of tooth strength and function after damage or decay.",
      "Seamless blending with natural teeth for a natural appearance.",
      "Prevention of further tooth damage and shifting of adjacent teeth.",
      "Durable solutions that can last for many years with proper care.",
    ],
    section2: "The Crown and Bridge Procedure",
    section2Content: [
      "Assessment and preparation of the affected tooth or teeth.",
      "Creation of impressions to design custom crowns or bridges.",
      "Placement of temporary restorations while the permanent ones are crafted.",
      "Fitting and cementation of the permanent crowns or bridges to ensure proper alignment and comfort.",
    ],
    section3: "Care and Maintenance",
    section3Content: [
      "Regular brushing and flossing to maintain oral hygiene and extend the life of crowns and bridges.",
      "Routine dental check-ups to monitor the condition and fit of restorations.",
      "Avoiding excessive force or hard foods that could damage the restorations.",
      "Addressing any issues or discomfort promptly with your dentist.",
    ],
  };

  return (
    <div>
      <ServiceCard
        pagetitle={content.pagetitle}
        imgUrl={content.imgUrl}
        desc1={content.desc1}
        desc2={content.desc2}
        f1={content.f1}
        f2={content.f2}
        f3={content.f3}
        section1={content.section1}
        section1Content={content.section1Content}
        section2={content.section2}
        section2Content={content.section2Content}
        section3={content.section3}
        section3Content={content.section3Content}
      />
    </div>
  );
};

export default Crown;
