import React, { useState, useRef } from "react";
import toast from "react-hot-toast";
import Map from "../Component/Map";
import TopNav from "../Component/TopBar";

const contactMethods = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
        />
      </svg>
    ),
    contact: "Ghattekulo Chowk, Kathmandu ",
    title: "Our office",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
        />
      </svg>
    ),
    contact: "+977-1-4770219, 9843142767",
    title: "Phone",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
        />
      </svg>
    ),
    contact: "agrimdentalhome0220@gmail.com",
    title: "E-mail",
  },
];

export default function Contact() {
  const [buttonText, setButtonText] = useState("Send Message");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [showThankYou, setShowThankYou] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setButtonText("Sending Message...");

    try {
      // Compose email body
      const mailBody = `Name: ${name}%0D%0AEmail: ${email}%0D%0APhone: ${number}%0D%0AMessage: ${message}`;

      // Open mail client with pre-filled details
      window.open(
        `agrimdentalhome0220@gmail.com?subject=Message from ${name}&body=${mailBody}`
      );

      // Reset form fields
      formRef.current.reset();
      setName("");
      setEmail("");
      setNumber("");
      setMessage("");

      // Show thank you message
      setShowThankYou(true);

      // Reset button text and submission state after 2 seconds
      setTimeout(() => {
        setButtonText("Send Message");
        setIsSubmitting(false);
        setShowThankYou(false); // Hide thank you message after showing for 2 seconds
      }, 2000);
    } catch (error) {
      toast.error("Failed to send message. Please try again later.");
      setButtonText("Send Message");
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <TopNav pageTitle="Contact Us" />
      <div
        className="md:px-0 px-4 flex flex-col md:gap-20 gap-14 justify-center items-center"
        data-aos="fade-up"
        data-aos-duration="800"
      >
        <div className="font-[sans-serif] mx-auto relative md:w-[90%] lg:w-[80%] bg-gray-100 rounded-xl overflow-hidden ">
          <div className="absolute -top-6 -left-6 w-20 h-20 rounded-full bg-blue-400"></div>
          <div className="absolute -bottom-6 -left-0 w-24 h-20 rounded-tr-[40px] bg-teal-200"></div>
          <div className="absolute -top-6 -right-6 w-20 h-20 rounded-full bg-blue-400"></div>
          <div className="absolute -bottom-6 -right-0 w-24 h-20 rounded-tl-[40px] bg-blue-300"></div>
          <div className="grid md:grid-cols-2">
            <div className="text-center p-6 xl:p-10 flex flex-col items-start justify-center">
              <h2 className="text-2xl  md:px-0 px-5  md:text-3xl text-accent-400 heading-font font-semibold">
                CONTACT <span className=" text-yellow-600">US</span> AGRIM
                DENTAL
              </h2>
              <ul className="mt-12 flex flex-wrap gap-x-12 gap-y-6 items-center lg:gap-x-24">
                {contactMethods.map((item, idx) => (
                  <li key={idx}>
                    <div className="mt-3 flex items-center gap-x-3">
                      <div className="flex-none animate-bounce text-primary-350 font-semibold">
                        {item.icon}
                      </div>
                      <p className="md:text-[19px] text-[18px] text-gray-600 cursor-pointer hover:text-gray-500 duration-200">
                        {item.contact}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <form
              onSubmit={handleSubmit}
              ref={formRef}
              className="bg-primary-300 pb-24 pt-8 px-6 xl:p-10"
            >
              <div className="max-w-sm mx-auto space-y-4">
                <input
                  type="text"
                  name="name"
                  required
                  placeholder="Name"
                  className="w-full bg-gray-100 rounded py-3 px-4 text-sm outline-none"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  type="email"
                  name="email"
                  required
                  placeholder="Email"
                  className="w-full bg-gray-100 rounded py-3 px-4 text-sm outline-none"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="tel"
                  name="number"
                  required
                  placeholder="Phone No."
                  className="w-full bg-gray-100 rounded py-3 px-4 text-sm outline-none"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />
                <textarea
                  placeholder="Message"
                  name="message"
                  required
                  rows="6"
                  className="w-full bg-gray-100 rounded px-4 text-sm pt-3 outline-none"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                <div className=" flex justify-center">
                  <button
                    type="submit"
                    className="button"
                    disabled={isSubmitting}
                  >
                    {buttonText}
                  </button>
                </div>
                {showThankYou && (
                  <p className="text-center text-green-600 mt-2">
                    Thank you for your message!
                  </p>
                )}
              </div>
            </form>
          </div>
        </div>
        <Map />
      </div>
    </>
  );
}
