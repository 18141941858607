import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import bannerimg from "./Pictures/Gallery/gallery2.jpg";
export default function Banner() {
  const [buttonText, setButtonText] = useState("Send Message");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [showThankYou, setShowThankYou] = useState(false);
  const formRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setButtonText("Sending Message...");

    try {
      // Compose email body
      const mailBody = `Name: ${name}%0D%0AEmail: ${email}%0D%0AAddress: ${address}`;

      // Open mail client with pre-filled details
      window.open(
        `mailto:agrimdentalhome0220@gmail.com?subject=Message from ${name}&body=${mailBody}`
      );

      // Reset form fields
      formRef.current.reset();
      setName("");
      setEmail("");
      setAddress("");

      // Show thank you message
      setShowThankYou(true);

      // Reset button text and submission state after 2 seconds
      setTimeout(() => {
        setButtonText("Send Message");
        setIsSubmitting(false);
        setShowThankYou(false); // Hide thank you message after showing for 2 seconds
      }, 2000);
    } catch (error) {
      toast.error("Failed to send message. Please try again later.");
      setButtonText("Send Message");
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <div className="relative">
        <img
          src={bannerimg}
          className="absolute inset-0 h-full w-full object-cover"
          alt=""
        />
        <div className="relative bg-primary-300 bg-opacity-75">
          <svg
            className="absolute inset-x-0 -bottom-1 text-white"
            viewBox="0 0 1160 163"
          >
            <path
              fill="currentColor"
              d="M-164 13L-104 39.7C-44 66 76 120 196 141C316 162 436 152 556 119.7C676 88 796 34 916 13C1036 -8 1156 2 1216 7.7L1276 13V162.5H1216C1156 162.5 1036 162.5 916 162.5C796 162.5 676 162.5 556 162.5C436 162.5 316 162.5 196 162.5C76 162.5 -44 162.5 -104 162.5H-164V13Z"
            ></path>
          </svg>
          <div className="relative mx-auto overflow-hidden px-4 py-16 sm:max-w-xl md:max-w-full md:px-24 lg:max-w-screen-xl lg:px-8 lg:py-20">
            <div className="flex flex-col items-center justify-between xl:flex-row">
              <div className="mb-12 w-full max-w-xl xl:mb-0 xl:w-7/12 xl:pr-16">
                <h2
                  data-aos="zoom-in"
                  data-aos-duration="800"
                  className="mb-10 max-w-lg  heading-font text-3xl font-bold tracking-tight text-white sm:text-5xl sm:leading-none"
                >
                  Agrim <span className=" text-yellow-600">Dental</span> Home
                </h2>
                <p
                  data-aos="fade-up"
                  data-aos-duration="800"
                  className="mb-10 max-w-xl text-base text-gray-100  font md:text-lg"
                >
                  Agrim Dental Home, founded in 2010 A.D. in Ghattekulo Chowk,
                  Kathmandu excels in quality and innovation, fostering strong
                  patient relationships.
                </p>
                <button
                  data-aos="fade-right"
                  data-aos-duration="800"
                  onClick={() => handleNavigation("/about")}
                  aria-label=""
                  className="inline-flex items-center font-semibold tracking-wider text-primary-350 transition-colors duration-200 hover:text-teal-300"
                >
                  Learn more
                  <svg
                    className="ml-2 inline-block w-3"
                    fill="currentColor"
                    viewBox="0 0 12 12"
                  >
                    <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z"></path>
                  </svg>
                </button>
              </div>
              <div className="w-full max-w-xl xl:w-5/12 xl:px-8">
                <div className="overflow-hidden rounded-xl border-t-4  border-primary-350 bg-white p-7 shadow-2xl shadow-primary-250 sm:p-10">
                  <h3 className="mb-4 heading-font text-xl font-bold text-black sm:mb-6 sm:text-center sm:text-2xl">
                    Direct Contact Us
                  </h3>
                  <form onSubmit={handleSubmit} ref={formRef}>
                    <div className="mb-1 sm:mb-2">
                      <label
                        htmlFor="name"
                        className="mb-1 inline-block font-medium text-primary-300"
                      >
                        Name
                      </label>
                      <input
                        placeholder="name"
                        required
                        type="text"
                        className="mb-2 h-12 w-full flex-grow appearance-none rounded border border-gray-300 bg-white px-4 shadow-sm ring-primary-250 transition duration-200 focus:border-primary-250 focus:outline-none focus:ring"
                        id="name"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div className="mb-1 sm:mb-2">
                      <label
                        htmlFor="email"
                        className="mb-1 inline-block font-medium text-primary-300"
                      >
                        E-mail
                      </label>
                      <input
                        placeholder="example@gamil.com"
                        required
                        type="email"
                        className="mb-2 h-12 w-full flex-grow appearance-none rounded border border-gray-300 bg-white px-4 shadow-sm ring-primary-250 transition duration-200 focus:border-primary-250 focus:outline-none focus:ring"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="mb-1 sm:mb-2">
                      <label
                        htmlFor="address"
                        className="mb-1 inline-block font-medium text-primary-300"
                      >
                        Address
                      </label>
                      <input
                        placeholder="address"
                        required
                        type="text"
                        className="mb-2 h-12 w-full flex-grow appearance-none rounded border border-gray-300 bg-white px-4 shadow-sm ring-primary-250 transition duration-200 focus:border-primary-250 focus:outline-none focus:ring"
                        id="address"
                        name="address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="mt-4 mb-2 sm:mb-4">
                      <button
                        type="submit"
                        className={`button${
                          isSubmitting ? "cursor-not-allowed" : ""
                        }`}
                        disabled={isSubmitting}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16px"
                          height="16px"
                          fill="#000"
                          className="mr-2 inline"
                          viewBox="0 0 548.244 548.244"
                        >
                          <path
                            fillRule="evenodd"
                            d="M392.19 156.054 211.268 281.667 22.032 218.58C8.823 214.168-.076 201.775 0 187.852c.077-13.923 9.078-26.24 22.338-30.498L506.15 1.549c11.5-3.697 24.123-.663 32.666 7.88 8.542 8.543 11.577 21.165 7.879 32.666L390.89 525.906c-4.258 13.26-16.575 22.261-30.498 22.338-13.923.076-26.316-8.823-30.728-22.032l-63.393-190.153z"
                            clipRule="evenodd"
                            data-original="#000000"
                          />
                        </svg>
                        {buttonText}
                      </button>
                    </div>
                    {showThankYou && (
                      <p className="text-center text-primary-300">
                        Thank you for contacting us!
                      </p>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
