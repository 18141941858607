import React from "react";

const Map = () => {
  return (
    <div className=" h-[25rem] w-full md:h-[28rem] md:w-[90%] lg:w-[80%]">
      <iframe
        title="Google Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.49839006044!2d85.3256382752536!3d27.701894276185698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb193e9a00213f%3A0xf6f974f19e1da235!2sAgrim%20dental%20home!5e0!3m2!1sen!2snp!4v1719905197246!5m2!1sen!2snp"
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Map;
