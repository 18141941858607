import React from "react";
import user from "../Component/Pictures/aboutimg.png";
import OurPatients from "../Component/OurPatients";
import CounterUp from "../Component/CounterUp";
import bannerimg from "../Component/Pictures/Gallery/agrim.jpg";

export default function About() {
  return (
    <>
      <div
        className="  md:px-0 px-4 flex  justify-center items-center"
        data-aos="fade-up"
        data-aos-duration="800"
      >
        <div className=" flex flex-col md:gap-8 gap-4 justify-center md:w-[80%] w-full">
          <div className="text-primary-300 md:text-3xl text-2xl font-semibold heading-font">
            About <span className=" text-yellow-600">Agrim Dental</span> Home
          </div>
          <div className="text-xl text-gray-800">
            Welcome to Agrim Dental Home—where your dental health is our
            priority. Our dedicated team is committed to delivering exceptional
            care in a friendly and relaxing environment.
          </div>
          <div className="text-[17px] text-gray-600">
            Agrim Dental Home, established in 2010 A.D, is located in the
            bustling area of Kathmandu at Dillibazar, Ghattekulo near Ganesh
            Mandir. Our clinic is a trusted name in dental care, known for
            offering a broad spectrum of dental services, from preventive care
            to advanced treatments, all tailored to meet the unique needs of our
            patients. We pride ourselves on using the latest dental technologies
            and techniques to ensure the highest quality of care. Our
            experienced and compassionate dentists are dedicated to making your
            visit as comfortable and stress-free as possible, ensuring you leave
            with a healthier, brighter smile. Whether you need a routine
            cleaning or more complex dental work, Agrim Dental Home is here to
            provide expert care in a warm and welcoming atmosphere.
          </div>

          <img
            className=" h-96 w-full object-cover rounded-md"
            src={bannerimg}
            alt=""
          />
          <div className="   grid lg:grid-cols-2   items-center md:mt-16 mt-3">
            <div className=" flex flex-col md:gap-7  gap-4 ">
              <div className=" cursor-pointer  text-primary-300 md:text-3xl text-2xl font-semibold heading-font">
                Our Clinical Facts
              </div>
              <div className=" text-[17px] text-gray-600">
                At Agrim Dental Home, we are dedicated to providing
                comprehensive and compassionate dental care. Our highly trained
                professionals utilize the latest advancements in dental
                technology to assess, diagnose, and treat a wide range of dental
                conditions. We prioritize patient safety and comfort, ensuring
                personalized attention and treatment tailored to each
                individual's needs. With a focus on evidence-based practice and
                collaborative care, we strive to achieve optimal outcomes and
                improve the overall oral health and well-being of our patients.
              </div>
              <div className=" md:mt-9 mt-4  grid md:grid-cols-3 md:gap-10 gap-8">
                <div className=" items-center flex flex-col md:gap-2 gap-1">
                  <p className="md:text-5xl text-4xl text-gray-700 cursor-pointer">
                    15
                  </p>
                  <h1 className=" text-yellow-500 md:text-2xl text-xl ">
                    Years Experience
                  </h1>
                </div>
                <div className=" items-center flex flex-col md:gap-2 gap-1">
                  <p className="md:text-5xl text-4xl text-gray-700  cursor-pointer">
                    14000+
                  </p>
                  <h1 className=" text-yellow-500 md:text-2xl text-xl  ">
                    Happy Patients
                  </h1>
                </div>
                <div className="  items-center flex flex-col md:gap-2 gap-1">
                  <p className=" md:text-5xl text-4xl text-gray-700 cursor-pointer  ">
                    100%
                  </p>
                  <h1 className=" text-yellow-500 md:text-2xl  text-xl ">
                    Satisfaction
                  </h1>
                </div>
              </div>
            </div>
            <div className=" flex justify-end">
              <img
                className=" cursor-pointer object-cover md:flex  hidden"
                src={user}
                alt=""
              />{" "}
            </div>
          </div>
        </div>
      </div>
      <OurPatients />
      <CounterUp />{" "}
    </>
  );
}
