// OurTeam.jsx
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "../assets/dipa.jpg";
import img2 from "../assets/par.jpg";
import img3 from "../assets/sonali.jpg";

import TopNav from "../Component/TopBar";

const dentists = [
  {
    name: "Dr.sonali Tiwari ",
    position: "Chairperson",
    img: img3,
  },
  {
    name: "Dr. Dipankar Shrestha",
    position: "Orthodontist",
    img: img1,
  },
  {
    name: "Dr. Praveen Jaiswal",
    position:
      "Oral and Maxillofacial Surgeon, Nepal Cancer Hospital, NMC No. 11227",
    img: img2,
  },
];

const Team = () => {
  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <TopNav pageTitle="Our Team" />
      <div
        className="relative bg-cover bg-center"
        data-aos="fade-up"
        data-aos-duration="800"
      >
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-center  px-4">
          <h1 className="text-4xl md:text-4xl font-bold mb-4 heading-font">
            <span className=" text-yellow-600">Our</span> Team
          </h1>
          <p className="text-lg md:text-xl mb-8">
            Meet our experienced and dedicated team of dentists
          </p>
          <Slider
            {...sliderSettings}
            className="w-full lg:max-w-[77rem] mx-auto"
          >
            {dentists.map((dentist, index) => (
              <div key={index} className="p-4">
                <div className=" rounded-lg p-4 text-center hover:-translate-y-2 duration-500">
                  <img
                    src={dentist.img}
                    alt={dentist.name}
                    className="w-full h-96 object-cover rounded-lg mb-4"
                  />
                  <h2 className="text-xl font-semibold  text-yellow-600">
                    {dentist.name}
                  </h2>
                  <h1 className="text-gray-900">{dentist.position}</h1>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Team;
