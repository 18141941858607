import React from "react";
import { Link } from "react-router-dom";
import xray from "../Component/Pictures/xray.webp";
import emergency from "../Component/Pictures/emergency.jpg";
import oral from "../Component/Pictures/checkup.jpg";
import restoration from "../Component/Pictures/restoration.webp";
import rct from "../Component/Pictures/rct.webp";
import crown from "../Component/Pictures/crown.webp";
import orthodontic from "../Component/Pictures/orthodonticservice.webp";
import implant from "../Component/Pictures/implant.webp";
import surgery from "../Component/Pictures/surgery.webp";
import scaling from "../Component/Pictures/scaling.webp";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

export default function AllServices() {
  useEffect(() => {
    Aos.init({
      duration: 800, // Customizing the animation duration
    });
  }, []);
  return (
    <div className=" md:mt-0 mt-16 md:px-0 px-4 flex flex-col md:gap-10 gap-6 justify-center items-center w-[90%] lg:w-[80%] mx-auto">
      <h1
        data-aos="zoom-in"
        data-aos-duration="800"
        className="  text-primary-300 md:text-2xl text-xl font-semibold underline heading-font"
      >
        All <span className=" text-yellow-600">Our</span> Services
      </h1>
      <span
        data-aos="fade-left"
        data-aos-duration="800"
        className=" md:px-5 md:text-[19px] text-[16px] text-gray-600"
      >
        At our dental clinic, we offer a wide range of services to ensure your
        oral health. From routine check-ups and cleanings to advanced cosmetic
        and restorative treatments, our experienced team of dentists is
        dedicated to providing the highest quality care. We specialize in
        preventive care, dental implants, orthodontics, teeth whitening, and
        more. Your comfort and satisfaction are our top priorities.
      </span>
      <div className=" flex justify-center items-center">
        <div className=" grid lg:grid-cols-3 md:grid-cols-2  md:gap-14 gap-10">
          {services.map((service, index) => (
            <div
              data-aos="fade-up"
              data-aos-duration="800"
              key={index}
              className="  rounded-md group bg-primary-300 hover:bg-[#252628]  duration-500"
            >
              <img
                className=" h-52  w-full cursor-pointer object-cover"
                src={service.img}
                alt=""
              />
              <div className=" flex flex-col gap-3 px-5 py-6">
                <p className="  text-yellow-500 md:text-2xl text-xl  duration-200 heading-font">
                  {service.name}
                </p>
                <span className=" text-gray-300 md:text-[19px] text-[16px] ">
                  {service.desc}
                </span>
                <div className=" flex justify-between">
                  <span></span>
                  <Link to={service.path} className=" button">
                    {service.btn}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const services = [
  {
    img: emergency,
    name: "Dental Emergency",
    desc: "A dental emergency can strike unexpectedly......",
    btn: "Learn More",
    path: "/emergency",
  },
  {
    img: oral,
    name: "Oral Checkup",
    desc: "During an oral checkup, your dentist will thoroughly ......",
    btn: "Learn More",
    path: "/oral",
  },
  {
    img: scaling,
    name: "Scaling & Polishing",
    desc: "Scaling and polishing are essential dental procedures......",
    btn: "Learn More",
    path: "/scaling",
  },
  {
    img: restoration,
    name: "Restoration",
    desc: " Restoration refers to the meticulous process of repairing......",
    btn: "Learn More",
    path: "/restoration",
  },
  {
    img: rct,
    name: "Root Canal Treatment (RCT)",
    desc: "Root Canal Treatment (RCT) is a crucial dental procedure ......",
    btn: "Learn More",
    path: "/rct",
  },
  {
    img: xray,
    name: "Digital teeth X-Ray",
    desc: "Digital teeth X-rays offer a multifaceted glimpse into the oral health......",
    btn: "Learn More",
    path: "/xray",
  },
  {
    img: crown,
    name: "Crown Bridge",
    desc: "A crown is a versatile dental restoration that encases a......",
    btn: "Learn More",
    path: "/crown",
  },
  {
    img: orthodontic,
    name: "Orthodontic",
    desc: "Orthodontic treatment is a specialized field dedicated to correcting dental......",
    btn: "Learn More",
    path: "/orthodontic",
  },
  {
    img: implant,
    name: "Dental Implant",
    desc: "Dental implants offer a revolutionary solution for individuals seeking......",
    btn: "Learn More",
    path: "/implant",
  },
  {
    img: surgery,
    name: "Dental Surgery",
    desc: "Dental surgery encompasses a spectrum of procedures aimed......",
    btn: "Learn More",
    path: "/surgery",
  },
];
