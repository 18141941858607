import React from "react";
import ServiceCard from "./ServiceCard";
import emergencyImg from "../../Component/Pictures/emergency.webp";

const DentalEmergency = () => {
  const content = {
    pagetitle: "Dental Emergency",
    imgUrl: emergencyImg,
    desc1:
      "A dental emergency can strike unexpectedly, causing immense discomfort and disrupting daily routines. From severe toothaches to broken teeth or injuries to the mouth, these situations demand immediate attention to alleviate pain and prevent further complications. Acting swiftly is crucial in such cases, as delays can exacerbate the problem and lead to more extensive treatment requirements. Contacting a dentist or seeking urgent dental care is paramount, as professionals can provide prompt assessment and necessary intervention to address the underlying issue effectively. Whether it's managing pain, treating infections, or restoring damaged teeth, timely dental care is essential for restoring oral health and well-being.",
    desc2:
      "In addition to seeking professional help, there are steps one can take at home to manage a dental emergency temporarily. Rinse the mouth with warm water to clean the affected area, and use over-the-counter pain medication to alleviate discomfort. Applying a cold compress to the outside of the mouth can help reduce swelling and numb the area. However, these measures are temporary solutions, and it's crucial to seek professional dental care as soon as possible for comprehensive treatment and resolution of the emergency.",
    f1: "Immediate care to alleviate pain and prevent further complications.",
    f2: "Round-the-clock service to address dental emergencies at any time.",
    f3: "Utilization of state-of-the-art technology for effective diagnosis and treatment.",
    section1: "Why Dental Emergency Is Important?",
    section1Content: [
      "Provides relief from severe pain.",
      "Prevents further damage to teeth and gums.",
      "Reduces the risk of infections spreading.",
      "Helps in maintaining overall oral health.",
    ],
    section2: "What Does a Dental Emergency Consist of at Agrim Dental?",
    section2Content: [
      "Handling severe toothaches and infections.",
      "Treatment for broken or knocked-out teeth.",
      "Care for injuries to gums, lips, or oral tissues.",
      "Management of dental abscesses and swelling.",
    ],
    section3: "Why Agrim Dental for Dental Treatments?",
    section3Content: [
      "Experienced and compassionate dental team.",
      "State-of-the-art technology and treatment methods.",
      "24/7 availability for emergencies.",
      "Comfortable and patient-focused care environment.",
    ],
  };

  return (
    <div>
      <ServiceCard
        pagetitle={content.pagetitle}
        imgUrl={content.imgUrl}
        desc1={content.desc1}
        desc2={content.desc2}
        f1={content.f1}
        f2={content.f2}
        f3={content.f3}
        section1={content.section1}
        section1Content={content.section1Content}
        section2={content.section2}
        section2Content={content.section2Content}
        section3={content.section3}
        section3Content={content.section3Content}
      />
    </div>
  );
};

export default DentalEmergency;
