import React, { useState, useEffect } from "react";
import logo from "../Pictures/logo2.jpg";
import { MdClose, MdKeyboardArrowDown } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { IoFilterOutline } from "react-icons/io5";

const NavLinks = () => {
  const location = useLocation();
  console.log(location.pathname);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const [showMenu, setShowMenu] = useState(false);
  const [showAboutSubMenu, setShowAboutSubMenu] = useState(false);
  const [showServiceSubMenu, setShowServiceSubMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleAboutClick = () => {
    setShowAboutSubMenu(!showAboutSubMenu);
    if (showServiceSubMenu) {
      setShowServiceSubMenu(false);
    }
  };

  const handleServiceClick = () => {
    setShowServiceSubMenu(!showServiceSubMenu);
    if (showAboutSubMenu) {
      setShowAboutSubMenu(false);
    }
  };

  const aboutLinks = [
    { to: "/about", text: "About Us" },
    { to: "/team", text: "Our Team" },
    { to: "/faq", text: "FAQs" },
    { to: "/appointment", text: "Book An Appointment" },
    { to: "/gallery", text: "Gallery" },
  ];

  const serviceLinks = [
    { to: "/emergency", text: "Dental Emergency" },
    { to: "/oral", text: "Oral Checkup" },
    { to: "/scaling", text: "Scaling & Polishing" },
    { to: "/restoration", text: "Restoration" },
    { to: "/rct", text: "Root Canal Treatment" },
    { to: "/xray", text: "Digital Teeth X-Ray" },
    { to: "/crown", text: "Crown Bridge" },
    { to: "/orthodontic", text: "Orthodontics" },
    { to: "/implant", text: "Dental Implant" },
    { to: "/surgery", text: "Dental Surgery" },
  ];

  return (
    <div className=" fixed left-0 right-0 top-0 z-50">
      {/* Navbar for larger devices more than 1024px */}
      <div className="bg-[rgba(0,0,0,255)] text-white hidden lg:block sticky top-0 h-[6rem] py-2">
        <div className="w-[80%] mx-auto flex items-center justify-between heading-font">
          <Link to="/" className="h-full w-[17rem] relative">
            <img
              src={logo}
              alt="logo"
              className="cursor-pointer object-content"
            />
          </Link>
          <p className=" text-primary-350 ml-[16rem] absolute mt-10 text-sm not-italic">
            15 Years in Dental Services
          </p>
          <div className="flex gap-7 items-center">
            <Link to="/" className="list-none cursor-pointer">
              HOME
            </Link>
            <li
              className="list-none cursor-pointer relative h-full py-7"
              onMouseEnter={() => setShowAboutSubMenu(true)}
              onMouseLeave={() => setShowAboutSubMenu(false)}
            >
              ABOUT{" "}
              <MdKeyboardArrowDown className="inline-block ml-1 text-lg" />
              {showAboutSubMenu && (
                <div
                  className="absolute flex flex-col gap-2 bg-white text-[#233542] w-[15rem] my-[1.62rem] rounded-md shadow-lg"
                  onMouseEnter={() => setShowAboutSubMenu(true)}
                  onMouseLeave={() => setShowAboutSubMenu(false)}
                >
                  {aboutLinks.map((link, index) => (
                    <Link
                      key={index}
                      to={link.to}
                      className={`py-2 px-4 nav-hover group ${
                        index === 0 ? "rounded-t-md" : ""
                      } ${
                        index === aboutLinks.length - 1 ? "rounded-b-md" : ""
                      }`}
                    >
                      <p className="group-hover:translate-x-2 duration-500">
                        {link.text}
                      </p>
                    </Link>
                  ))}
                </div>
              )}
            </li>
            <li
              className="list-none cursor-pointer relative py-7"
              onMouseEnter={() => setShowServiceSubMenu(true)}
              onMouseLeave={() => setShowServiceSubMenu(false)}
            >
              SERVICES{" "}
              <MdKeyboardArrowDown className="inline-block ml-1 text-lg" />
              {showServiceSubMenu && (
                <ul
                  className="absolute flex flex-col bg-white text-[#233542] mt-[1.62rem] w-[15rem] rounded-md shadow-lg"
                  onMouseEnter={() => setShowServiceSubMenu(true)}
                  onMouseLeave={() => setShowServiceSubMenu(false)}
                >
                  {serviceLinks.map((link, index) => (
                    <Link
                      key={index}
                      to={link.to}
                      className={`py-2 nav-hover px-4 group ${
                        index === 0 ? "rounded-t-md" : ""
                      } ${
                        index === serviceLinks.length - 1 ? "rounded-b-md" : ""
                      }`}
                    >
                      <p className="group-hover:translate-x-2 duration-500">
                        {link.text}
                      </p>
                    </Link>
                  ))}
                </ul>
              )}
            </li>
            <Link to="/contact" className="list-none cursor-pointer">
              CONTACT US
            </Link>

            <Link to="/carrer" className="list-none cursor-pointer">
              CARRIER
            </Link>
            <Link to="/appointment">
              <button className="button">Book Appointment</button>
            </Link>
          </div>
        </div>
      </div>

      {/* Navbar for smaller devices less than 1024px */}
      <div className="lg:hidden">
        <div className="flex justify-between items-center px-7 py-2 h-[6rem] shadow-md bg-[rgba(0,0,0,255)]">
          <Link to="/" className="h-full w-[15rem] relative">
            <img
              src={logo}
              alt="logo"
              className="object-contain h-full cursor-pointer"
            />
            <p className=" text-primary-350 text-xs -mt-[0.8rem] ml-20  not-italic">
              15 Years in Dental Service
            </p>
          </Link>

          <div
            className="text-3xl t text-yellow-400 font-semibold"
            onClick={toggleMenu}
          >
            {showMenu ? <MdClose /> : <IoFilterOutline />}
          </div>
        </div>

        {/* Link content */}
        <div
          className={`${
            showMenu ? "translate-x-0" : "-translate-x-full"
          } lg:hidden transform transition-transform duration-300 ease-in-out fixed top-0 left-0 bottom-0 w-[75%] bg-white shadow-md flex flex-col gap-4`}
        >
          <div
            className="py-2 shadow-md w-full h-[5.5rem] bg-black pl-3 flex justify-start"
            onClick={toggleMenu}
          >
            <Link to="/">
              <img
                src={logo}
                alt="logo"
                className="h-full w-full object-contain heading-font cursor-pointer"
              />
            </Link>
          </div>
          <div className="flex flex-col gap-6 px-3">
            <Link
              to="/"
              className="list-none cursor-pointer font-semibold text-lg text-slate-800"
              onClick={toggleMenu}
            >
              Home
            </Link>
            <div
              className="list-none cursor-pointer h-full"
              onClick={handleAboutClick}
            >
              <div className="flex justify-between font-semibold text-lg text-slate-800">
                About
                <MdKeyboardArrowDown className="text-xl" />
              </div>
              {showAboutSubMenu && (
                <div className="text-[#233542] px-3 flex flex-col gap-2">
                  {aboutLinks.map((link, index) => (
                    <Link
                      key={index}
                      to={link.to}
                      className="py-2"
                      onClick={toggleMenu}
                    >
                      {link.text}
                    </Link>
                  ))}
                </div>
              )}
            </div>
            <li
              className="list-none cursor-pointer"
              onClick={handleServiceClick}
            >
              <div className="flex justify-between font-semibold text-lg text-slate-800">
                Services <MdKeyboardArrowDown className="text-xl" />
              </div>
              {showServiceSubMenu && (
                <div className="text-[#233542] px-3 flex flex-col gap-2">
                  {serviceLinks.map((link, index) => (
                    <Link
                      key={index}
                      to={link.to}
                      className="py-2"
                      onClick={toggleMenu}
                    >
                      {link.text}
                    </Link>
                  ))}
                </div>
              )}
            </li>
            <Link
              to="/carrer"
              className="list-none cursor-pointer font-semibold text-lg text-slate-800"
              onClick={toggleMenu}
            >
              Carrer
            </Link>
            <Link
              to="/contact"
              className="list-none cursor-pointer font-semibold text-lg text-slate-800"
              onClick={toggleMenu}
            >
              Contact Us
            </Link>

            <Link to="/appointment" onClick={toggleMenu}>
              <button className="button">Book Appointment</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavLinks;
