import React from "react";
import { useState } from "react";
import { FaPhone } from "react-icons/fa6";
import { BsWhatsapp } from "react-icons/bs";

const ContactBtn = () => {
  const [showConfirm, setShowConfirm] = useState(false);

  const handleCallClick = () => {
    setShowConfirm(true);
  };

  const handleConfirmCall = () => {
    setShowConfirm(false);
    window.location.href = "tel:+97714770219";
  };

  const handleCancelCall = () => {
    setShowConfirm(false);
  };

  return (
    <div className="md:hidden fixed bottom-0 left-0 w-full flex">
      <div className="w-1/2">
        <button
          onClick={handleCallClick}
          className="w-full flex items-center justify-center bg-blue-400 text-white py-3 not-italic hover:bg-blue-500 heading-font"
        >
          <FaPhone className="mr-2" /> Call
        </button>
      </div>
      <div className="w-1/2">
        <a
          href="https://wa.me/9843142767"
          target="_blank"
          rel="noopener noreferrer"
          className="w-full flex items-center justify-center bg-green-400 text-white py-3 not-italic hover:bg-green-500 heading-font"
        >
          <BsWhatsapp className="mr-2 font-semibold" /> Message
        </a>
      </div>
      {showConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded-lg shadow-lg text-center">
            <p className="mb-4">Do you want to call +977 14770219?</p>
            <button
              onClick={handleConfirmCall}
              className="bg-blue-500 text-white py-2 px-4 rounded mr-2 hover:bg-blue-600"
            >
              Yes, Call
            </button>
            <button
              onClick={handleCancelCall}
              className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactBtn;
