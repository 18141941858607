import React from "react";
import { MdLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
import { MdOutlinePhoneIphone } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import {
  FaFacebookF,
  FaPhoneAlt,
  FaInstagram,
  FaRegClock,
} from "react-icons/fa";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
const Footer = () => {
  return (
    <div
      className=" bg-[rgba(0,0,0,255)] text-white pt-14"
      data-aos="fade-down"
      data-aos-duration="800"
    >
      <div className=" w-[80%] flex flex-col lg:flex-row justify-center gap-10 mx-auto">
        <div className=" w-full lg:w-[30%]">
          <p className=" footer-header heading-font">About Us</p>
          <div className=" footer-text flex flex-col gap-3">
            <p className=" flex gap-2 items-center padding-border">
              {" "}
              <MdLocationOn className=" footer-icons text-4xl md:text-2xl" />{" "}
              Ghattekulo Chowk, Kathmandu{" "}
            </p>
            <p className=" flex gap-3 items-center padding-border">
              <FaPhoneAlt className=" footer-icons" /> 1-4770219
            </p>
            <p className=" flex gap-2 items-center padding-border">
              <MdOutlinePhoneIphone className=" footer-icons text-xl" />
              9843142767, 9816815305
            </p>
            <p className=" flex gap-3 items-center">
              {" "}
              <HiOutlineMail className=" footer-icons text-lg" />{" "}
              agrimdentalhome0220@gmail.com{" "}
            </p>
            <div> </div>
          </div>

          <div className=" flex gap-4 mt-10">
            <a
              href="https://www.facebook.com/profile.php?id=61554171923866&mibextid=LQQJ4d"
              className=" bg-[#FED000] hover:bg-blue-900 p-2 hover:-translate-y-1 duration-700"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF className=" text-xl " />
            </a>{" "}
            <a
              href="https://www.instagram.com/agrim_dental_home_dental_clinc?igsh=dWVrY3d0bWdpeGJw&utm_source=qr"
              className=" bg-[#FED000] p-2 hover:bg-pink-600 hover:-translate-y-1 duration-700"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className=" text-xl" />
            </a>
          </div>
        </div>

        <div className=" w-full lg:w-[30%]">
          <p className=" footer-header heading-font">Services</p>
          <div className=" footer-text flex flex-col gap-3">
            <Link
              to="/emergency"
              className=" flex gap-2 items-center footer-services"
            >
              {" "}
              <MdOutlineKeyboardArrowRight className=" footer-icons text-xl font-semibold" />{" "}
              Dental Emergency
            </Link>
            <Link
              to="/oral"
              className=" flex gap-2 items-center footer-services"
            >
              {" "}
              <MdOutlineKeyboardArrowRight className=" footer-icons text-xl font-semibold" />{" "}
              Oral Checkup
            </Link>
            <Link
              to="/scaling"
              className=" flex gap-2 items-center footer-services"
            >
              {" "}
              <MdOutlineKeyboardArrowRight className=" footer-icons text-xl font-semibold" />{" "}
              Scaling & Polishing
            </Link>
            <Link
              to="/restoration"
              className=" flex gap-2 items-center footer-services"
            >
              {" "}
              <MdOutlineKeyboardArrowRight className=" footer-icons text-xl font-semibold" />{" "}
              Restoration
            </Link>
            <Link
              to="/rct"
              className=" flex gap-2 items-center footer-services"
            >
              {" "}
              <MdOutlineKeyboardArrowRight className=" footer-icons text-xl font-semibold" />{" "}
              Root Canal Treatment
            </Link>
            <Link
              to="/xray"
              className=" flex gap-2 items-center footer-services"
            >
              {" "}
              <MdOutlineKeyboardArrowRight className=" footer-icons text-xl font-semibold" />{" "}
              Dental teeth X-Ray
            </Link>
            <Link
              to="/crown"
              className=" flex gap-2 items-center footer-services"
            >
              {" "}
              <MdOutlineKeyboardArrowRight className=" footer-icons text-xl font-semibold" />{" "}
              Crown Bridge
            </Link>
            <Link
              to="/orthodontic"
              className=" flex gap-2 items-center footer-services"
            >
              {" "}
              <MdOutlineKeyboardArrowRight className=" footer-icons text-xl font-semibold" />{" "}
              Orthodontic
            </Link>
            <Link
              to="/implant"
              className=" flex gap-2 items-center footer-services"
            >
              {" "}
              <MdOutlineKeyboardArrowRight className=" footer-icons text-xl font-semibold" />{" "}
              Dental Implant
            </Link>
            <Link
              to="/surgery"
              className=" flex gap-2 items-center footer-services"
            >
              {" "}
              <MdOutlineKeyboardArrowRight className=" footer-icons text-xl font-semibold" />{" "}
              Dental Surgery
            </Link>
          </div>
        </div>

        <div className=" w-full lg:w-[30%]">
          <p className=" footer-header heading-font">Opening Hours</p>
          <div className=" footer-text flex flex-col gap-2">
            <div className=" flex justify-between items-center padding-border">
              <p className=" flex gap-2 items-center">
                <FaRegClock className="footer-icons" /> Sunday
              </p>
              <p>8:00 AM-7:30PM</p>
            </div>
            <div className=" flex justify-between items-center padding-border">
              <p className=" flex gap-2 items-center ">
                <FaRegClock className="footer-icons" /> Monday
              </p>
              <p>8:00 AM-7:30PM</p>
            </div>
            <div className=" flex justify-between items-center padding-border">
              <p className=" flex gap-2 items-center">
                <FaRegClock className="footer-icons" />
                Tuesday
              </p>
              <p>8:00 AM-7:30PM</p>
            </div>
            <div className=" flex justify-between items-center padding-border">
              <p className=" flex gap-2 items-center">
                <FaRegClock className="footer-icons" /> Wednesday
              </p>
              <p>8:00 AM-7:30PM</p>
            </div>
            <div className=" flex justify-between items-center padding-border">
              <p className=" flex gap-2 items-center">
                <FaRegClock className="footer-icons" /> Thursday
              </p>
              <p>8:00 AM-7:30PM</p>
            </div>
            <div className=" flex justify-between items-center padding-border">
              <p className=" flex gap-2 items-center">
                <FaRegClock className="footer-icons" /> Friday
              </p>
              <p>8:00 AM-7:30PM</p>
            </div>
            <div className=" flex justify-between items-center">
              <p className=" flex gap-2 items-center">
                <FaRegClock className="footer-icons" /> Saturday
              </p>
              <p>8:00 AM-7:30PM</p>
            </div>
          </div>
        </div>
      </div>

      <div className=" mt-10 pt-7 pb-9 bg-slate-950 text-center lg:text-start lg:pl-[11rem] footer-text">
        Copyright &copy; Agrim Dental Home. All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
