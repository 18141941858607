import React from "react";
import { FaHome } from "react-icons/fa";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
const TopBar = ({ pageTitle }) => {
  return (
    <div className=" bg-gray-100 -mt-16 text-black mb-[5rem]">
      <div className="  flex flex-col gap-2  lg:gap-0 lg:flex-row justify-between items-center w-[80%] mx-auto py-[2rem]">
        <h1 className=" text-xl md:text-3xl lg:text-3xl font-bold mt-1 lg:mt-0 heading-font">
          {pageTitle}
        </h1>
        <div className="flex items-center">
          <FaHome className="text-black mr-1 lg:mr-2  lg:text-xl" />
          <Link to="/" className=" lg:mr-2 text-sm lg:text-xl">
            Home
          </Link>
          <MdOutlineKeyboardArrowRight className=" lg:mr-2 text-xl font-bold" />
          <span
            className="
         text-sm lg:text-lg"
          >
            {pageTitle}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
