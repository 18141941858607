import React, { useState } from "react";
import TopNav from "../Component/TopBar";
import banner from "../Component/Pictures/dentalfilling.jpg";
// import { FaPlus, FaMinus } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import { HiMinusSmall } from "react-icons/hi2";

export default function FAQ() {
  const faqsList = [
    {
      q: "What are the common signs of dental problems?",
      a: "Common signs of dental problems include toothache, sensitivity to hot or cold, swollen or bleeding gums, bad breath, and difficulty chewing or swallowing. Regular check-ups can help detect issues early and maintain oral health.",
    },
    {
      q: "How often should I visit the dentist?",
      a: "It is generally recommended to visit the dentist every six months for a routine check-up and cleaning. However, the frequency may vary based on individual oral health needs and the dentist's advice.",
    },
    {
      q: "What should I do in case of a dental emergency?",
      a: "In case of a dental emergency, such as a knocked-out tooth, severe toothache, or a broken tooth, contact our clinic immediately. Rinse your mouth with warm water and apply a cold compress to reduce swelling until you can see the dentist.",
    },
    {
      q: "How can I prevent cavities and gum disease?",
      a: "To prevent cavities and gum disease, practice good oral hygiene by brushing your teeth twice a day, flossing daily, using mouthwash, and visiting the dentist regularly. A balanced diet and limiting sugary snacks can also help maintain oral health.",
    },
    {
      q: "What are the options for replacing missing teeth?",
      a: "There are several options for replacing missing teeth, including dental implants, bridges, and dentures. Your dentist can recommend the best option based on your specific needs and oral health condition.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <>
      <TopNav pageTitle="FAQ" />
      <div
        className="w-[80%] flex flex-col lg:flex-row gap-10 mx-auto"
        data-aos="fade-up"
        data-aos-duration="800"
      >
        <img
          src={banner}
          alt="banner"
          className="w-[35rem] h-[21rem] rounded-md"
        />
        <div>
          <p className="text-2xl font-semibold lg:text-4xl mb-2 heading-font">
            Agrim Dental Home
          </p>
          Agrim Dental Home is a state-of-the-art dental clinic dedicated to
          providing exceptional dental care for patients of all ages. Our clinic
          offers a comprehensive range of services, including general dentistry,
          cosmetic dentistry, orthodontics, teeth whitening, dental implants,
          root canal treatment, and pediatric dentistry. At Agrim Dental Home,
          we prioritize patient comfort and satisfaction, utilizing the latest
          technology and techniques to ensure the best possible outcomes. Our
          experienced and compassionate team is committed to creating a
          welcoming environment where patients can receive personalized care
          tailored to their unique needs. Whether you're visiting for a routine
          check-up or a specialized procedure, Agrim Dental Home is your trusted
          partner in achieving and maintaining optimal oral health.
        </div>
      </div>
      <div
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="1500"
        className="flex overflow-y-hidden md:px-0 px-4 justify-center items-center mt-[3rem]"
      >
        <div
          className="md:flex lg:w-10/12 bg-gray-100 rounded-md
        p-3  md:pb-10 justify-between w-full"
        >
          <div className="md:px-20 md:pt-4 pt-12 w-full">
            <div className="space-y-4">
              <p className="flex md:justify-center items-center text-accent-450 text-2xl md:text-4xl heading-font  mb-10">
                Frequently  <span className=" text-yellow-600 px-1">Asked</span> Questions
              </p>

              <div className="max-w-3xl mx-auto p-4">
                {faqsList.map((faq, index) => (
                  <div key={index} className="mb-4">
                    <div
                      className=" text-black bg-white shadow-md p-4 flex justify-between items-center cursor-pointer rounded-t-lg"
                      onClick={() => toggleAccordion(index)}
                    >
                      <h3 className="text-sm md:text-lg ">{faq.q}</h3>
                      <span className=" text-xl font-semibold">
                        {activeIndex === index ? <HiMinusSmall /> : <GoPlus />}
                      </span>
                    </div>
                    {activeIndex === index && (
                      <div className="bg-gray-100 text-xs text-slate-700 md:text-base p-4 transition-max-height duration-500 ease-in-out rounded-b-md shadow-md">
                        <p>{faq.a}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
