import React from "react";
import ServiceCard from "./ServiceCard";
import surgery from "../../Component/Pictures/surgery.webp";

const DentalSurgery = () => {
  const content = {
    pagetitle: "Dental Surgery",
    imgUrl: surgery,
    desc1:
      "Dental surgery encompasses a spectrum of procedures aimed at addressing various oral health issues, ranging from routine extractions to complex reconstructive surgeries. One common procedure is wisdom teeth extraction, often necessitated by impaction or overcrowding in the mouth. Through precise surgical techniques and advanced anesthesia, dentists can safely remove these third molars, mitigating the risk of infection and preventing potential complications. Additionally, dental surgeons specialize in corrective jaw surgeries to rectify bite misalignments, addressing functional impairments and enhancing facial aesthetics. By employing cutting-edge technology such as 3D imaging and CAD/CAM systems, these procedures are tailored to each patient's unique anatomical needs, ensuring optimal outcomes and improved oral function.",
    desc2:
      "Furthermore, dental surgery plays a crucial role in the management of oral diseases such as periodontitis and oral cancers. Surgical interventions like gum grafting and flap surgery are employed to restore gum health and preserve the integrity of the tooth-supporting structures. In cases of oral cancer, surgical excision of tumors followed by reconstructive procedures helps restore both function and aesthetics, facilitating the patient's journey towards recovery. Collaborating closely with multidisciplinary healthcare teams, dental surgeons provide comprehensive care, emphasizing preventive measures and patient education to maintain long-term oral health and overall well-being.",
    f1: "Skilled and experienced dental surgeons for a variety of procedures.",
    f2: "Advanced methods to reduce discomfort and recovery time.",
    f3: "Full range of surgical services, from extractions to complex procedures.",
    section1: "Why Dental Surgery Is Important?",
    section1Content: [
      "Addresses complex dental issues that can't be resolved by non-surgical means.",
      "Prevents the progression of oral diseases and complications.",
      "Enhances both functional and aesthetic aspects of oral health.",
      "Restores proper oral function, including chewing and speaking.",
    ],
    section2: "What Does Dental Surgery Involve at Agrim Dental?",
    section2Content: [
      "Comprehensive diagnostic assessments using advanced imaging.",
      "Customized surgical plans tailored to individual patient needs.",
      "A wide range of procedures, from extractions to corrective jaw surgery.",
      "Post-surgical care and follow-up for optimal recovery and results.",
    ],
    section3: "Why Choose Agrim Dental for Dental Surgery?",
    section3Content: [
      "Experienced and specialized dental surgeons.",
      "Utilization of cutting-edge technology and techniques.",
      "Patient-centered approach focusing on comfort and safety.",
      "Holistic care, including preventive education and post-operative support.",
    ],
  };

  return (
    <div>
      <ServiceCard
        pagetitle={content.pagetitle}
        imgUrl={content.imgUrl}
        desc1={content.desc1}
        desc2={content.desc2}
        f1={content.f1}
        f2={content.f2}
        f3={content.f3}
        section1={content.section1}
        section1Content={content.section1Content}
        section2={content.section2}
        section2Content={content.section2Content}
        section3={content.section3}
        section3Content={content.section3Content}
      />
    </div>
  );
};

export default DentalSurgery;
