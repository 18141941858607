import React, { useState } from "react";
import carrer from "../Component/Pictures/dentalfilling.jpg";
import TopBar from "../Component/TopBar";

const Carrer = () => {
  const [formData, setFormData] = useState({
    applicationFor: "",
    doctor: "",
    city: "",
    qualification: "",
    yearOfInternshipComplete: "",
    age: "",
    mobileNo: "",
    emailAddress: "",
    cv: null,
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, cv: e.target.files[0] });
  };

  const validateForm = () => {
    let newErrors = {};

    if (!formData.applicationFor) {
      newErrors.applicationFor = "Application for the post of is required";
    }

    if (!formData.doctor) {
      newErrors.doctor = "Doctor is required";
    }

    if (!formData.city) {
      newErrors.city = "City is required";
    }

    if (!formData.qualification) {
      newErrors.qualification = "Qualification is required";
    }

    if (!formData.yearOfInternshipComplete) {
      newErrors.yearOfInternshipComplete =
        "Year of internship completion is required";
    }

    if (!formData.age) {
      newErrors.age = "Age is required";
    } else if (isNaN(formData.age)) {
      newErrors.age = "Age must be a number";
    }

    if (!formData.mobileNo) {
      newErrors.mobileNo = "Mobile number is required";
    } else if (!/^\d{10}$/.test(formData.mobileNo)) {
      newErrors.mobileNo = "Mobile number must be 10 digits";
    }

    if (!formData.emailAddress) {
      newErrors.emailAddress = "Email address is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.emailAddress)) {
      newErrors.emailAddress = "Email address is invalid";
    }

    if (!formData.cv) {
      newErrors.cv = "CV upload is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Submit the form
      console.log("Form submitted successfully:", formData);
      // Reset the form
      setFormData({
        applicationFor: "",
        doctor: "",
        city: "",
        qualification: "",
        yearOfInternshipComplete: "",
        age: "",
        mobileNo: "",
        emailAddress: "",
        cv: null,
      });
      setErrors({});
    }
  };

  return (
    <div>
      <TopBar pageTitle="Carrier" />
      <div
        className="w-[95%] md:w-[80%] mx-auto px-4 md:text-lg"
        div
        data-aos="fade-up"
        data-aos-duration="800"
      >
        <h2 className="text-3xl font-bold mb-6 text-center not-italic ">
          Apply <span className=" text-yellow-600">For A </span>Job
        </h2>

        <p
          className="mb-10 text-justify text-slate-600 md:mx-8"
          data-aos="fade-down"
        >
          You must visit your dentist twice a year for a dental checkup”, is
          something you may have heard many a time, isn’t it? In fact, a regular
          dental checkup is key to a healthy oral cavity consisting of healthy
          teeth and gums. A dental checkup involves two steps – an evaluation of
          your oral hygiene and dental cleaning (also called scaling and
          polishing).
        </p>

        <img
          src={carrer}
          alt="Career"
          className="w-full mb-10 h-96  object-cover"
          data-aos="zoom-in"
        />

        <div
          className="mb-10 text-justify text-slate-600 md:mx-8"
          data-aos="fade-up"
        >
          <p className="text-slate-700 font-semibold text-lg md:text-2xl mb-2 not-italic">
            Why <span className=" text-yellow-600">Join Agrim</span> Dental?
          </p>
          <ul className="list-disc ml-5" data-aos="fade-up">
            <li>
              Agrim Dental is the largest chain of dental clinics with a vision
              to provide dental care services directly or indirectly to every
              individual in need of dental treatment.
            </li>
            <li>
              Agrim Dental currently has a core team of over 550 accomplished
              and expert dentists.
            </li>
            <li>
              Agrim Dental follows high standards of quality and service and
              thus hires the best talent followed by detailed training and
              continuous education.
            </li>
            <li>
              Training involves learning the latest dental techniques and
              keeping up to date with all innovations in dentistry with the help
              of a continual program of internal education and development.
            </li>
            <li>
              As the fastest growing dental chain, professional development and
              upward growth in roles and responsibilities is encouraged
              promoting an upward career growth.
            </li>
            <li>
              With a supportive and welcoming work environment, you can develop
              your existing skills while discovering new ones.
            </li>
          </ul>
        </div>

        <h3 className="text-2xl text-center font-bold mb-6  text-slate-600 mt-7">
          Want <span className=" text-yellow-600">to work</span> with Us?
        </h3>

        <form
          onSubmit={handleSubmit}
          className="max-w-4xl mx-auto text-slate-600  shadow-md px-4 py-10"
          data-aos="zoom-out"
          data-aos-duration="1000"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="mb-3">
              <label htmlFor="applicationFor" className="block mb-2">
                Application for the post of
              </label>
              <input
                type="text"
                id="applicationFor"
                name="applicationFor"
                value={formData.applicationFor}
                onChange={handleChange}
                className={`w-full border border-gray-300 p-2 rounded-md ${
                  errors.applicationFor ? "border-red-500" : ""
                }`}
              />
              {errors.applicationFor && (
                <span className="text-red-500 text-sm">
                  {errors.applicationFor}
                </span>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="doctor" className="block mb-2">
                Doctor
              </label>
              <input
                type="text"
                id="doctor"
                name="doctor"
                value={formData.doctor}
                onChange={handleChange}
                className={`w-full border border-gray-300 p-2 rounded-md ${
                  errors.doctor ? "border-red-500" : ""
                }`}
              />
              {errors.doctor && (
                <span className="text-red-500 text-sm">{errors.doctor}</span>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="city" className="block mb-2">
                City
              </label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                className={`w-full border border-gray-300 p-2 rounded-md ${
                  errors.city ? "border-red-500" : ""
                }`}
              />
              {errors.city && (
                <span className="text-red-500 text-sm">{errors.city}</span>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="qualification" className="block mb-2">
                Qualification
              </label>
              <input
                type="text"
                id="qualification"
                name="qualification"
                value={formData.qualification}
                onChange={handleChange}
                className={`w-full border border-gray-300 p-2 rounded-md ${
                  errors.qualification ? "border-red-500" : ""
                }`}
              />
              {errors.qualification && (
                <span className="text-red-500 text-sm">
                  {errors.qualification}
                </span>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="yearOfInternshipComplete" className="block mb-2">
                Year of Internship Completion
              </label>
              <input
                type="text"
                id="yearOfInternshipComplete"
                name="yearOfInternshipComplete"
                value={formData.yearOfInternshipComplete}
                onChange={handleChange}
                className={`w-full border border-gray-300 p-2 rounded-md ${
                  errors.yearOfInternshipComplete ? "border-red-500" : ""
                }`}
              />
              {errors.yearOfInternshipComplete && (
                <span className="text-red-500 text-sm">
                  {errors.yearOfInternshipComplete}
                </span>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="age" className="block mb-2">
                Age
              </label>
              <input
                type="text"
                id="age"
                name="age"
                value={formData.age}
                onChange={handleChange}
                className={`w-full border border-gray-300 p-2 rounded-md ${
                  errors.age ? "border-red-500" : ""
                }`}
              />
              {errors.age && (
                <span className="text-red-500 text-sm">{errors.age}</span>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="mobileNo" className="block mb-2">
                Mobile Number
              </label>
              <input
                type="text"
                id="mobileNo"
                name="mobileNo"
                value={formData.mobileNo}
                onChange={handleChange}
                className={`w-full border border-gray-300 p-2 rounded-md ${
                  errors.mobileNo ? "border-red-500" : ""
                }`}
              />
              {errors.mobileNo && (
                <span className="text-red-500 text-sm">{errors.mobileNo}</span>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="emailAddress" className="block mb-2">
                Email Address
              </label>
              <input
                type="text"
                id="emailAddress"
                name="emailAddress"
                value={formData.emailAddress}
                onChange={handleChange}
                className={`w-full border border-gray-300 p-2 rounded-md ${
                  errors.emailAddress ? "border-red-500" : ""
                }`}
              />
              {errors.emailAddress && (
                <span className="text-red-500 text-sm">
                  {errors.emailAddress}
                </span>
              )}
            </div>

            <div className="mb-3 col-span-1 md:col-span-2">
              <label htmlFor="cv" className="block mb-2">
                Upload CV
              </label>
              <input
                type="file"
                id="cv"
                name="cv"
                accept=".pdf,.doc,.docx"
                onChange={handleFileChange}
                className="w-full border border-gray-300 p-2 rounded-md"
              />
              {errors.cv && (
                <span className="text-red-500 text-sm">{errors.cv}</span>
              )}
            </div>
          </div>
          <div className="flex justify-center items-center ">
            <button type="submit" className=" button px-8">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Carrer;
