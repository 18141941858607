import { useState } from "react";
import { FaFacebookMessenger } from "react-icons/fa6";
import logo from "./Pictures/logo1.jpg";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const FacebookBtn = () => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="fixed bottom-[7rem] md:bottom-[5rem] right-5 z-[9999]">
      {/* Floating Action Button */}
      <button
        className="bg-blue-500 text-white rounded-full w-12 h-12 flex items-center justify-center shadow-sm shadow-slate-700 hover:bg-blue-600 focus:outline-none"
        onClick={togglePopup}
      >
        <FaFacebookMessenger className="size-5" />
      </button>

      {/* Popup */}
      {showPopup && (
        <div className="bg-white border border-gray-200 shadow-sm shadow-blue-400 rounded-lg p-4 max-w-md w-[20rem] absolute bottom-24 right-0 ">
          <div className="flex flex-col items-start ml-2 mb-2">
            <img
              src={logo}
              alt="Logo"
              className="w-12 h-12 mr-2 rounded-full mb-2"
            />
            <div>
              <p className="font-semibold">Hi there!!</p>
              <p className="">How can we help you?</p>
            </div>
          </div>
          <a
            href="https://www.facebook.com/photo/?fbid=122130327140139064&set=a.122094773234139064&__tn__=%3C"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-between bg-blue-500 text-white rounded-full py-2 px-4 mt-2 hover:bg-blue-600"
          >
            <p>Chat with us</p>
            <MdOutlineKeyboardArrowRight className="text-xl" />
          </a>
        </div>
      )}
    </div>
  );
};

export default FacebookBtn;
