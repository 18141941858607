import React from "react";
import ServiceCard from "./ServiceCard";
import implant from "../../Component/Pictures/implant.webp";

const DentalImplant = () => {
  const content = {
    pagetitle: "Dental Implant",
    imgUrl: implant,
    desc1:
      "Dental implants offer a revolutionary solution for individuals seeking to restore their smile and oral function. These small titanium posts are surgically placed into the jawbone, serving as sturdy anchors for replacement teeth. What sets dental implants apart is their ability to mimic the natural structure of teeth, providing unparalleled stability and longevity. Once implanted, the bone fuses with the titanium, creating a robust foundation for the prosthetic tooth or teeth to be attached. This integration not only ensures a secure fit but also helps prevent bone loss in the jaw, maintaining facial structure and overall oral health. With proper care, dental implants can last a lifetime, offering patients renewed confidence and the freedom to eat, speak, and smile with ease.",
    desc2:
      "Beyond the functional benefits, dental implants can significantly enhance one's quality of life. Unlike traditional dentures or bridges, implants eliminate the inconvenience of removable appliances, allowing individuals to enjoy their favorite foods without restrictions. Moreover, they look and feel like natural teeth, providing a seamless aesthetic appearance. Whether replacing a single tooth or securing a full arch of prosthetic teeth, dental implants offer a comprehensive solution that restores both the beauty and functionality of a complete smile. With advancements in implant technology and techniques, more patients than ever can benefit from this transformative dental procedure.",
    f1: "Long-lasting replacement for missing teeth.",
    f2: "Implants that function and feel like natural teeth",
    f3: "Prevention of bone loss in the jaw where teeth are missing",
    section1: "Why Dental Implants Are Important?",
    section1Content: [
      "Provide a permanent solution for missing teeth.",
      "Help maintain facial structure by preventing bone loss.",
      "Improve overall oral health and hygiene.",
      "Enhance chewing and speaking abilities.",
    ],
    section2: "What Does a Dental Implant Procedure Involve at Agrim Dental?",
    section2Content: [
      "Comprehensive assessment and personalized treatment plan.",
      "Surgical placement of titanium posts into the jawbone.",
      "Custom-made prosthetic teeth that match natural teeth.",
      "Follow-up care to ensure successful integration and healing.",
    ],
    section3: "Why Choose Agrim Dental for Dental Implants?",
    section3Content: [
      "Experienced specialists in implant dentistry.",
      "State-of-the-art technology for precise implant placement.",
      "Focus on patient comfort and personalized care.",
      "Commitment to long-term oral health and aesthetics.",
    ],
  };

  return (
    <div>
      <ServiceCard
        pagetitle={content.pagetitle}
        imgUrl={content.imgUrl}
        desc1={content.desc1}
        desc2={content.desc2}
        f1={content.f1}
        f2={content.f2}
        f3={content.f3}
        section1={content.section1}
        section1Content={content.section1Content}
        section2={content.section2}
        section2Content={content.section2Content}
        section3={content.section3}
        section3Content={content.section3Content}
      />
    </div>
  );
};

export default DentalImplant;