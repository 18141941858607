import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import SectionHeadline from "./SectionHeadline";

const TestimonialData = [
  {
    id: 1,
    name: "Rajan Shahi",
    text: "I was always nervous about going to the dentist until I found this clinic. The staff is incredibly friendly, and they make you feel comfortable from the moment you walk in. The care I received was top-notch.",
    img: "https://picsum.photos/103/103",
  },

  {
    id: 3,
    name: "Loki Chaulagain",
    text: "Fantastic experience! The dentists here are very professional and attentive. They took the time to explain everything and answered all my questions. My teeth have never felt better. Thank you",
    img: "https://picsum.photos/104/104",
  },
  {
    id: 4,
    name: "Subeena Gurung",
    text: "Best dental clinic I’ve ever visited. The entire team is so welcoming and efficient. The procedures were painless, and I’m extremely happy with the results. I’ll definitely be coming back for all my dental needs!",
    img: "https://picsum.photos/103/103",
  },
];

const Testimonial = () => {
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    pauseOnFocus: true,
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      className="flex justify-center items-center"
      data-aos="fade-up"
      data-aos-duration="800"
    >
      <div className="md:w-10/12 w-full">
        {/* header section */}
        <div className="text-center max-w-[600px] mx-auto">
          {/* <SectionHeadline title=" Testimonial " /> */}
          <p className="text-3xl  font-serif  font-semibold text-primary">
            What our <span className=" text-yellow-600">customers</span> are
            saying
          </p>
        </div>

        {/* Testimonial cards */}
        <div>
          <Slider {...settings}>
            {TestimonialData.map((data) => (
              <div key={data.id} className=" md:mt-7 mt-6">
                <div className="flex flex-col gap-4 shadow-lg py-8 px-6 mx-6 rounded-xl bg-primary-300 relative">
                  <div className="mb-4">
                    <img
                      src={data.img}
                      alt={data.name}
                      className="rounded-full w-20 h-20"
                    />
                  </div>
                  <div className="flex flex-col items-center gap-4">
                    <div className="space-y-3 text-center text-primary-350">
                      <p className="text-md font-medium text-slate-300 ">
                        {data.text}
                      </p>
                      <h1 className="text-xl font-bold heading-font">
                        {data.name}
                      </h1>
                    </div>
                  </div>
                  <p className="   text-primary-350 text-8xl font-serif absolute top-0 right-0">
                    ,,
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
