import React from "react";
import Slider from "react-slick";
import CompareImage from "react-compare-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import gteeth1 from "./Pictures/gteeth1.jpg";
import gteeth2 from "./Pictures/gteeth2.jpg";
import gteeth3 from "./Pictures/gteeth3.jpg";
import bteeth1 from "./Pictures/bteeth1.jpg";
import bteeth2 from "./Pictures/bteeth2.jpg";
import bteeth3 from "./Pictures/bteeth3.jpg";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

const CompareSlider = () => {
  const slides = [
    { id: 1, title: "SMILE MAKEOVER", before: bteeth1, after: gteeth1 },
    { id: 2, title: "GAP CLOSURE", before: bteeth2, after: gteeth2 },
    {
      id: 3,
      title: "INSTANT TEETH WHITENING",
      before: bteeth3,
      after: gteeth3,
    },
  ];

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="absolute top-[55%] justify-center flex text-white right-1 lg:right-[12rem] text-xl cursor-pointer rounded-full z-10 bg-primary-300  p-2"
        onClick={onClick}
      >
        <IoIosArrowForward className="size-6" />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="absolute top-[55%] text-white left-1 lg:left-[12rem] cursor-pointer text-xl rounded-full z-10 bg-primary-300 p-2"
        onClick={onClick}
      >
        <IoIosArrowBack className="size-6" />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    swipe: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div
      className=" bg-white flex justify-center items-center"
      data-aos="fade-down"
      data-aos-duration="800"
    >
      <div className=" w-[90%] lg:w-[80%]">
        <p
          data-aos="zoom-in"
          data-aos-duration="800"
          className=" md:text-4xl text-2xl  font-semibold text-center heading-font"
        >
          The Best <span className=" text-yellow-600">Possible</span> Result
        </p>
        <Slider {...settings}>
          {slides.map((slide) => (
            <div
              key={slide.id}
              className="flex justify-center items-center gap-4 flex-col"
            >
              <div className="flex flex-col items-center gap-1 my-2">
                <p className="md:text-2xl text-xl">Drag to see the result</p>
              </div>
              <div className="w-full max-w-[50rem] mx-auto">
                <CompareImage
                  leftImage={slide.before}
                  rightImage={slide.after}
                  sliderLineColor="#0dadf4"
                  handleSize={50}
                  aspectRatio=""
                  containerClassName="h-60 md:h-80 lg:h-96 max-h-[400px]"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CompareSlider;
