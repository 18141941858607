import React from "react";
import HomeSlider from "../Component/HomeSlider";
// import Card from "../Component/Card";
import Services from "../Component/Services";
import Testimonial from "../Component/Testimonail";
import Banner from "../Component/Banner";
import OurTeam from "../Component/OurTeam";
import CompareSlider from "../Component/ComapreSlider";
import SuccessStory from "../Component/SuccessStory";
import GetCard from "../Component/GetCard";
import AppointmentForm from "../Component/AppointmentCard";
const Home = () => {
  return (
    <div className=" flex flex-col gap-20">
      <HomeSlider />
      <div className=" hidden">
        <AppointmentForm />
      </div>{" "}
      <Services />
      <Testimonial />
      <GetCard />
      <Banner />
      <CompareSlider />
      <SuccessStory />
      <OurTeam />
    </div>
  );
};

export default Home;
