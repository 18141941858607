import React from "react";
import img1 from "./Pictures/getimg1.png";
import img2 from "./Pictures/getimg2.png";
import { IoCheckmarkSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
const GetCard = () => {
  const Services = [
    {
      id: Math.random().toString(),
      list: "24-hour emergency dental service",
    },
    {
      id: Math.random().toString(),
      list: "Comprehensive dental treatments",
    },
    {
      id: Math.random().toString(),
      list: "Advanced dental technology",
    },
    {
      id: Math.random().toString(),
      list: "Experienced and skilled dental professionals",
    },
    {
      id: Math.random().toString(),
      list: "Personalized treatment plans",
    },
    {
      id: Math.random().toString(),
      list: "Comfortable and welcoming environment",
    },
  ];
  return (
    <div className="  md:mt-10 w-[90%] lg:w-[80%] mx-auto flex flex-col lg:flex-row gap-6 lg:gap-[5rem]">
      <div className=" flex flex-col md:flex-row gap-5">
        <img
          src={img1}
          alt="get-img1"
          className=" w-[17rem] h-[15rem] md:h-[17rem]"
          data-aos="fade-down"
          data-aos-duration="1000"
        />
        <img
          src={img2}
          alt="get-img2"
          className=" h-[17rem] w-fit md:h-[21rem] md:mt-[7rem] mt-[2rem]"
          data-aos="fade-up"
          data-aos-duration="1000"
        />
      </div>

      <div>
        <p className=" text-primary-350 text-3xl md:text-5xl heading-font font-semibold">
          What You Get
        </p>
        <ul className=" mt-5  md:text-lg font-semibold flex flex-col gap-4 text-slate-700">
          {Services.map((data) => {
            return (
              <li key={data.id} className=" flex gap-2 items-center">
                <IoCheckmarkSharp className="text-2xl font-bold text-black" />
                {data.list}
              </li>
            );
          })}
        </ul>

        <div className=" mt-7">
          <Link
            to="/about"
            className=" bg-primary-350 hover:text-white  rounded-tr-2xl px-4 py-3 text-lg rounded-bl-2xl hover:rounded-tr-none hover:rounded-bl-none duration-500 "
          >
            Learn more
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GetCard;
