import React from "react";
import ServiceCard from "./ServiceCard";
import xray from "../../Component/Pictures/xray.webp";

const Xray = () => {
  const content = {
    pagetitle: "Digital Teeth X-Ray",
    imgUrl: xray,
    desc1:
      "In the realm of modern dentistry, the advent of digital X-ray technology has revolutionized the way dental professionals diagnose and treat oral health issues. Through the lens of digital imaging, the intricate landscape of the teeth and surrounding structures is illuminated with unparalleled clarity and precision. Gone are the days of cumbersome film development and storage; digital X-rays provide instantaneous results, minimizing patient discomfort and reducing radiation exposure. With advanced software algorithms, these digital images can be enhanced, manipulated, and zoomed in on, allowing dentists to detect even the most minute abnormalities or signs of decay. The transition to digital X-rays represents a pivotal leap forward in dental care, ushering in an era of efficiency, accuracy, and patient-centricity.",
    desc2:
      "Digital teeth X-rays offer a multifaceted glimpse into the oral health landscape, revealing crucial details that may escape the naked eye. From detecting cavities hidden between teeth to assessing the integrity of dental restorations, these high-definition images empower dentists to formulate comprehensive treatment plans tailored to each patient's unique needs. Furthermore, digital X-rays facilitate seamless collaboration between dental professionals, enabling easy sharing of images for consultations or referrals. By harnessing the power of digital technology, dentistry transcends its traditional boundaries, delivering enhanced diagnostics and personalized care that prioritizes both oral health and overall well-being. In the digital age, the smile's radiance is not only preserved but also safeguarded through the transformative capabilities of digital teeth X-rays.",
    f1: "Clear and detailed images for accurate diagnosis.",
    f2: "Minimal radiation exposure.",
    f3: "Quick processing and analysis of X-ray images.",
    section1: "Benefits of Digital Teeth X-Rays",
    section1Content: [
      "Enhanced clarity and detail for precise diagnosis and treatment planning.",
      "Reduced radiation exposure compared to traditional film X-rays.",
      "Faster image acquisition and processing, leading to more efficient patient care.",
      "Ability to easily share and review images with other dental professionals.",
    ],
    section2: "The Digital X-Ray Process",
    section2Content: [
      "Positioning of the patient and the X-ray sensor to capture the required images.",
      "Acquisition of digital images through an X-ray machine with minimal discomfort.",
      "Immediate display of images on a computer screen for review and analysis.",
      "Discussion of findings and treatment options based on the X-ray results.",
    ],
    section3: "Safety and Efficiency",
    section3Content: [
      "Digital X-rays significantly lower radiation levels compared to conventional X-rays.",
      "Advanced imaging technology ensures high-quality results with reduced patient exposure.",
      "Efficient processing and storage of digital images enhance workflow and reduce wait times.",
      "Ongoing advancements in digital X-ray technology continue to improve diagnostic accuracy and patient care.",
    ],
  };

  return (
    <div>
      <ServiceCard
        pagetitle={content.pagetitle}
        imgUrl={content.imgUrl}
        desc1={content.desc1}
        desc2={content.desc2}
        f1={content.f1}
        f2={content.f2}
        f3={content.f3}
        section1={content.section1}
        section1Content={content.section1Content}
        section2={content.section2}
        section2Content={content.section2Content}
        section3={content.section3}
        section3Content={content.section3Content}
      />
    </div>
  );
};

export default Xray;
