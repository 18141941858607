import React, { useState } from "react";
import img1 from "../Component/Pictures/Gallery/gallery1.jpg";
import img2 from "../Component/Pictures/Gallery/gallery2.jpg";
import img3 from "../Component/Pictures/Gallery/gallery3.jpg";
import img4 from "../Component/Pictures/Gallery/gallery4.jpg";
import img5 from "../Component/Pictures/Gallery/gallery7.jpg";
import img6 from "../Component/Pictures/Gallery/gallery5.jpg";
import img7 from "../Component/Pictures/Gallery/gallery6.jpg";
import img8 from "../Component/Pictures/Gallery/gallery3.jpg";

import TopNav from "../Component/TopBar";

export default function Gallery() {
  const images = [
    { src: img1, alt: "Louvre" },
    { src: img3, alt: "Louvre" },
    { src: img2, alt: "Louvre" },
    { src: img4, alt: "Louvre" },
    { src: img5, alt: "Louvre" },
    { src: img6, alt: "Louvre" },
    { src: img7, alt: "Louvre" },
    { src: img8, alt: "Louvre" },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openModal = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const showPrevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? 0 : prevIndex - 1));
  };

  const showNextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? images.length - 1 : prevIndex + 1
    );
  };

  return (
    <>
      <TopNav pageTitle="Gallery" />
      <div
        className="md:px-0 px-4 flex justify-center items-center"
        data-aos="fade-up"
        data-aos-duration="800"
      >
        <div className="md:w-10/12 w-full">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-10">
            {images.map((image, index) => (
              <div key={index} className="mb-4">
                <div className="relative overflow-hidden bg-cover bg-no-repeat h-72">
                  <img
                    src={image.src}
                    className="w-full   hover:translate-y-px duration-300 h-full object-cover rounded-md cursor-pointer"
                    alt={image.alt}
                    onClick={() => openModal(index)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {isOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90 p-4">
            <button
              className="absolute top-4 right-4 text-white text-4xl"
              onClick={closeModal}
            >
              &times;
            </button>
            <button
              className={`absolute left-4 text-white text-3xl ${
                currentIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={showPrevImage}
              disabled={currentIndex === 0}
            >
              &#9664;
            </button>
            <div className="flex justify-center items-center w-[85vw] md:h-[70vh] h-[50vh]">
              <img
                src={images[currentIndex].src}
                alt={images[currentIndex].alt}
                className="w-full h-full object-cover"
              />
            </div>
            <button
              className={`absolute right-4 text-white text-3xl ${
                currentIndex === images.length - 1
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              onClick={showNextImage}
              disabled={currentIndex === images.length - 1}
            >
              &#9654;
            </button>
          </div>
        )}
      </div>
    </>
  );
}
