import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "../assets/dipa.jpg";
import img2 from "../assets/par.jpg";
import img3 from "../assets/sonali.jpg";
import teambanner from "./Pictures/imgbanner.jpg";

const dentists = [
  {
    name: "Dr.sonali Tiwari ",
    position: "Chairperson",
    img: img3,
  },

  {
    name: "Dr. Dipankar Shrestha",
    position: "Orthodontist",
    img: img1,
  },
  {
    name: "Dr. Praveen Jaiswal",
    position:
      "Oral and Maxillofacial Surgeon, Nepal Cancer Hospital, NMC No. 11227",
    img: img2,
  },
];

const OurTeam = () => {
  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      className="relative bg-cover bg-center bg-fixed h-[90vh] md:h-[95vh]"
      style={{ backgroundImage: `url(${teambanner})` }}
    >
      <div className="absolute inset-0 bg-primary-300 opacity-80"></div>
      <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white px-4">
        <h1 className="text-4xl md:text-4xl font-bold mb-4 heading-font">
          <span className="text-yellow-600">Our</span> Team
        </h1>
        <p className="text-lg md:text-xl mb-8">
          Meet our experienced and dedicated team of dentists
        </p>
        <Slider {...sliderSettings} className="w-full lg:max-w-[77rem] mx-auto">
          {dentists.map((dentist, index) => (
            <div key={index} className="p-4">
              <div
                data-aos="zoom-in"
                data-aos-duration="800"
                className="bg-white rounded-lg p-4 text-center hover:-translate-y-2 duration-500"
              >
                <img
                  src={dentist.img}
                  alt={dentist.name}
                  className="w-full h-96 object-cover rounded-lg mb-4"
                />
                <h2 className="text-xl font-semibold text-yellow-500">
                  {dentist.name}
                </h2>
                <h1 className="text-gray-900">{dentist.position}</h1>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default OurTeam;
