import React from "react";
import ServiceCard from "./ServiceCard";
import orthodontic from "../../Component/Pictures/orthodonticservice.webp";

const Orthodontics = () => {
  const content = {
    pagetitle: "Orthodontics",
    imgUrl: orthodontic,
    desc1:
      "Orthodontic treatment is a specialized field dedicated to correcting dental misalignments and irregularities. Through the use of various tools and techniques, orthodontists tailor treatment plans to address each patient's unique needs. Traditional braces, composed of metal brackets and wires, remain a steadfast option for effectively straightening teeth. However, advancements in orthodontic technology have introduced more discreet alternatives such as ceramic braces and clear aligners, providing patients with greater comfort and aesthetic appeal during their treatment journey. Beyond cosmetic enhancements, orthodontic interventions contribute significantly to overall oral health by reducing the risk of dental decay, gum disease, and other issues associated with misaligned teeth and jaws.",
    desc2:
      "Moreover, orthodontic care extends beyond mere aesthetics, facilitating improved chewing function, speech clarity, and long-term dental health. By aligning the teeth and jaws correctly, orthodontists not only enhance the appearance of their patients' smiles but also promote optimal oral function and well-being. Through personalized treatment plans and ongoing support, orthodontic professionals play a vital role in helping individuals achieve not only straighter teeth but also healthier mouths and greater confidence in their smiles.",
    f1: "Custom treatment plans tailored to individual needs.",
    f2: "Use of the latest braces and clear aligners for effective teeth straightening.",
    f3: "Correct alignment of teeth for better oral hygiene and health.",
    section1: "Why Orthodontic Treatment?",
    section1Content: [
      "Straightening teeth to improve appearance and confidence.",
      "Correcting bite issues to enhance chewing and speech.",
      "Reducing the risk of tooth decay and gum disease.",
      "Preventing excessive wear on teeth and jaws.",
    ],
    section2: "Types of Orthodontic Treatments Available",
    section2Content: [
      "Traditional metal braces for reliable teeth straightening.",
      "Ceramic braces that blend with natural tooth color.",
      "Clear aligners for a virtually invisible treatment option.",
      "Lingual braces placed behind the teeth for discreet correction.",
    ],
    section3: "What to Expect During Orthodontic Treatment",
    section3Content: [
      "Initial consultation to evaluate and discuss treatment options.",
      "Customized treatment plan development.",
      "Regular check-ups to monitor progress and adjust appliances.",
      "Ongoing support and guidance for maintaining oral hygiene.",
    ],
  };

  return (
    <div>
      <ServiceCard
        pagetitle={content.pagetitle}
        imgUrl={content.imgUrl}
        desc1={content.desc1}
        desc2={content.desc2}
        f1={content.f1}
        f2={content.f2}
        f3={content.f3}
        section1={content.section1}
        section1Content={content.section1Content}
        section2={content.section2}
        section2Content={content.section2Content}
        section3={content.section3}
        section3Content={content.section3Content}
      />
    </div>
  );
};

export default Orthodontics;
