import React from "react";

import before from "../../src/Component/Pictures/before.jpg";
import after from "../../src/Component/Pictures/after.jpg";

export default function SuccessStory() {
  return (
    <div
      className=" md:px-0 px-4 flex justify-center items-center"
      data-aos="fade-down"
      data-aos-duration="800"
    >
      <div className=" md:flex  gap-20 md:w-10/12 w-full">
        <div className=" flex flex-col md:gap-8 gap-5">
          <h1 className=" cursor-pointer  text-primary-300 md:text-3xl text-2xl font-semibold heading-font">
            Success <span className=" text-yellow-600">Story</span>  The Dental 
          </h1>
          <div className="  flex flex-col  md:gap-4 gap-3">
            <h2   data-aos="zoom-in"
          data-aos-duration="800" className=" cursor-pointer md:text-2xl text-[19px]   font-semibold">
              Teeth Whitening
            </h2>
            <p className=" text-[18px] text-gray-700">
              Before undergoing any teeth whitening procedure, it's crucial to
              consult with a dental professional to determine the most suitable
              option for your specific needs and to ensure the health of your
              teeth and gums. Dentists can assess your oral health.
            </p>
            <p className=" text-[17px] text-gray-600">
              After undergoing teeth whitening, many individuals experience a
              significant improvement in the brightness and appearance of their
              teeth, leading to increased self-confidence and a more youthful
              smile. Whether it's through professional treatments or at-home
              methods.
            </p>
          </div>
        </div>
        <div className=" md:mt-0 mt-8 flex flex-col gap-10">
          <img
            className=" rounded-md hover:scale-105 duration-500  md:h-42 md:w-[38vw] object-cover cursor-pointer"
            src={before}
            alt=""
          />
          <img
            className=" rounded-md hover:scale-105 duration-500 md:h-42 md:w-[38vw] object-cover cursor-pointer"
            src={after}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
