import React from "react";
import ServiceCard from "../ServicePages/ServiceCard";
import checkup_img from "../../Component/Pictures/routine-chechup.jpg";
const RoutineCheckup = () => {
  const content = {
    pagetitle: "Routine Checkup",
    imgUrl: checkup_img,
    desc1:
      "At Agrim Dental Home, established in 2066 BS, routine checkups are a cornerstone of our preventive dental care services. Regular dental checkups are essential for maintaining optimal oral health, preventing dental issues before they become significant problems. During a routine checkup, our experienced dental professionals conduct a thorough examination of your teeth, gums, and mouth, looking for signs of decay, gum disease, and other potential issues. We also perform professional cleanings to remove plaque and tartar buildup, ensuring your smile stays healthy and bright.",
    desc2:
      "Routine checkups at Agrim Dental Home are not just about preventing cavities and gum disease; they are also about educating our patients on the best practices for maintaining oral hygiene. Our team provides personalized advice on brushing, flossing, and dietary habits that contribute to good oral health. By visiting us regularly, you can catch potential problems early and avoid more extensive and costly treatments in the future. Trust Agrim Dental Home to be your partner in achieving and maintaining a healthy, beautiful smile.",
    f1: "Thorough examination of teeth, gums, and mouth.",
    f2: "Professional cleanings to remove plaque and tartar buildup.",
    f3: "Personalized oral hygiene advice and education.",
  };
  return (
    <div>
      <ServiceCard
        pagetitle={content.pagetitle}
        imgUrl={content.imgUrl}
        desc1={content.desc1}
        desc2={content.desc2}
        f1={content.f1}
        f2={content.f2}
        f3={content.f3}
      />{" "}
    </div>
  );
};

export default RoutineCheckup;
