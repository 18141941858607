import React from "react";
import { Toaster } from "react-hot-toast";
import NavLinks from "./Component/Navbar/NavLinks";
import Footer from "./Component/Footer";
import { Route, Routes } from "react-router-dom";
import ScrollToTopButton from "./Component/ScrollToTop";
// import FloatingButton from "./Component/WhatsappBtn";
import Home from "./Pages/Home";
import About from "./Pages/About";
import FAQ from "./Pages/FAQ";
import Contact from "./Pages/Contact";
import Gallery from "./Pages/Gallery";
import Team from "./Pages/Team";
import Appointment from "./Pages/Appointment";
import OralCheckup from "./Pages/ServicePages/OralCheckup";
import DentalEmergency from "./Pages/ServicePages/DentalEmergency";
import Scaling from "./Pages/ServicePages/Scaling";
import Restoration from "./Pages/ServicePages/Restoration";
import Rct from "./Pages/ServicePages/Rct";
import Xray from "./Pages/ServicePages/Xray";
import Crown from "./Pages/ServicePages/Crown";
import Orthodontics from "./Pages/ServicePages/Orthodontics";
import DentalImplant from "./Pages/ServicePages/DentalImplant";
import DentalSurgery from "./Pages/ServicePages/DentalSurgery";
import TeethWhitening from "./Pages/CardLinks/TeethWhitening";
import RoutineCheckup from "./Pages/CardLinks/'RoutineCheckup";
import ContactBtn from "./Pages/ServicePages/ContactBtn";
import FacebookBtn from "./Component/FacebookBtn";
import Carrer from "./Pages/Carrer";
const App = () => {
  return (
    <div className=" overflow-hidden text-font">
      <NavLinks />
      <div className=" md:py-40 py-36">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/team" element={<Team />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/appointment" element={<Appointment />} />
          <Route path="/oral" element={<OralCheckup />} />
          <Route path="/emergency" element={<DentalEmergency />} />
          <Route path="/scaling" element={<Scaling />} />
          <Route path="/restoration" element={<Restoration />} />
          <Route path="/rct" element={<Rct />} />
          <Route path="/xray" element={<Xray />} />
          <Route path="/crown" element={<Crown />} />
          <Route path="/orthodontic" element={<Orthodontics />} />
          <Route path="/implant" element={<DentalImplant />} />
          <Route path="/surgery" element={<DentalSurgery />} />
          <Route path="/whitening" element={<TeethWhitening />} />
          <Route path="/routineCheckup" element={<RoutineCheckup />} />
          <Route path="/carrer" element={<Carrer />} />
        </Routes>
      </div>
      <ContactBtn />
      <FacebookBtn />
      <ScrollToTopButton />
      <Toaster />
      <Footer />
    </div>
  );
};

export default App;
