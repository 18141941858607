import React, { useState } from "react";
import TopBar from "../Component/TopBar";
import banner from "../Component/Pictures/dentalfilling.jpg";
import { useForm } from "react-hook-form";

const Appointment = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = (data) => {
    const subject = "New Appointment Request";
    const body = `
      Name: ${data.name}\n
      Service: ${data.service}\n
      Email: ${data.email}\n
      Phone: ${data.phone}\n
      Appointment Date: ${data.appointmentDate}\n
      Message: ${data.message || "N/A"}
    `;

    const mailtoLink = `mailto:agrimdentalhome0220@gmail.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;

    setIsSubmitted(true);
  };

  return (
    <div>
      <TopBar pageTitle="Appointment" />
      <div
        className="w-[80%] flex flex-col lg:flex-row gap-10 mx-auto"
        data-aos="fade-down"
        data-aos-duration="800"
      >
        <img
          src={banner}
          alt="banner"
          className="w-[35rem] h-[21rem] rounded-md"
        />
        <div>
          <p className="text-2xl font-semibold lg:text-4xl mb-2 heading-font">
            Dental<span className=" text-yellow-600 px-2">Agrim</span> Home
          </p>
          <p>
            Agrim Dental Home is a state-of-the-art dental clinic dedicated to
            providing exceptional dental care for patients of all ages. Our
            clinic offers a comprehensive range of services, including general
            dentistry, cosmetic dentistry, orthodontics, teeth whitening, dental
            implants, root canal treatment, and pediatric dentistry. At Agrim
            Dental Home, we prioritize patient comfort and satisfaction,
            utilizing the latest technology and techniques to ensure the best
            possible outcomes. Our experienced and compassionate team is
            committed to creating a welcoming environment where patients can
            receive personalized care tailored to their unique needs. Whether
            you're visiting for a routine check-up or a specialized procedure,
            Agrim Dental Home is your trusted partner in achieving and
            maintaining optimal oral health.
          </p>
        </div>
      </div>

      {/* appointment form */}

      <div
        className="max-w-screen-lg mx-auto p-4 md:mt-10"
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="1500"
      >
        <div className=" md:text-4xl text-2xl text-center font-semibold mt-[3rem] mb-[2rem] heading-font">
          Book <span className=" text-yellow-600">An </span> Appointment
        </div>
        {isSubmitted ? (
          <div
            className="bg-green-100 border-t border-b border-green-500 text-green-700 px-4 py-3"
            role="alert"
          >
            <p className="font-bold">Success!</p>
            <p className="text-sm">Your appointment has been booked.</p>
          </div>
        ) : (
          <form
            className="w-full lg:w-4/5 mx-auto bg-white p-6 rounded shadow-md"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="name"
              >
                Name
              </label>
              <input
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.name ? "border-red-500" : ""
                }`}
                id="name"
                type="text"
                placeholder="Enter your name"
                {...register("name", { required: "Name is required" })}
              />
              {errors.name && (
                <p className="text-red-500 text-xs italic">
                  {errors.name.message}
                </p>
              )}
            </div>

            <div className="mb-4 flex flex-col lg:flex-row lg:space-x-4">
              <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="service"
                >
                  Select Service
                </label>
                <select
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    errors.service ? "border-red-500" : ""
                  }`}
                  id="service"
                  {...register("service", { required: "Service is required" })}
                >
                  <option value="dental emergency">Dental Emergency</option>
                  <option value="oral checkup">Oral Checkup</option>
                  <option value="scaling & polishing">
                    Scaling & Polishing
                  </option>
                  <option value="restoration">Restoration</option>
                  <option value="rct">Root Canal Treatment (RCT)</option>
                  <option value="teeth x-ray">Dental teeth X-Ray</option>
                  <option value="crown bridge">Crown Bridge</option>
                  <option value="orthodontics">Orthodontics</option>
                  <option value="dental implant">Dental Implant</option>
                  <option value="dental surgery">Dental Surgery</option>
                </select>
                {errors.service && (
                  <p className="text-red-500 text-xs italic">
                    {errors.service.message}
                  </p>
                )}
              </div>

              
            </div>

            <div className="mb-4 flex flex-col lg:flex-row lg:space-x-4">
              <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    errors.email ? "border-red-500" : ""
                  }`}
                  id="email"
                  type="email"
                  placeholder="Enter your email"
                  {...register("email", { required: "Email is required" })}
                />
                {errors.email && (
                  <p className="text-red-500 text-xs italic">
                    {errors.email.message}
                  </p>
                )}
              </div>

              <div className="w-full lg:w-1/2">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="phone"
                >
                  Phone
                </label>
                <input
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    errors.phone ? "border-red-500" : ""
                  }`}
                  id="phone"
                  type="tel"
                  placeholder="Enter your phone number"
                  {...register("phone", {
                    required: "Phone number is required",
                  })}
                />
                {errors.phone && (
                  <p className="text-red-500 text-xs italic">
                    {errors.phone.message}
                  </p>
                )}
              </div>
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="appointmentDate"
              >
                Appointment Date
              </label>
              <input
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.appointmentDate ? "border-red-500" : ""
                }`}
                id="appointmentDate"
                type="date"
                placeholder="Select appointment date"
                {...register("appointmentDate", {
                  required: "Appointment date is required",
                })}
              />
              {errors.appointmentDate && (
                <p className="text-red-500 text-xs italic">
                  {errors.appointmentDate.message}
                </p>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="message"
              >
                Message (Optional)
              </label>
              <textarea
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="message"
                placeholder="Enter your message"
                {...register("message")}
              ></textarea>
            </div>

            <div className="flex items-center justify-between">
              <button
                className="bg-yellow-600 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Book Appointment
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Appointment;
