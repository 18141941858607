import React from "react";
import ServiceCard from "./ServiceCard";
import scaling from "../../Component/Pictures/scaling.webp";

const Scaling = () => {
  const content = {
    pagetitle: "Scaling & Polishing",
    imgUrl: scaling,
    desc1:
      "Scaling and polishing are essential dental procedures that contribute to oral health maintenance and the prevention of dental diseases. Scaling involves the removal of plaque, tartar, and stains from the surfaces of teeth, particularly in areas that are difficult to reach through regular brushing and flossing. This process is typically performed using specialized dental instruments to gently scrape away buildup without causing damage to the tooth enamel. By eliminating these deposits, scaling helps to reduce the risk of gum disease, cavities, and bad breath, promoting overall oral hygiene.",
    desc2:
      "Polishing follows scaling and is performed to smooth the surfaces of the teeth, removing any residual plaque or stains left after scaling. This process involves using a gentle abrasive paste and a dental polishing tool to give your teeth a smooth, shiny finish. Polishing enhances the appearance of teeth by removing surface stains, making them look brighter and healthier. It also helps to create a smoother surface that makes it harder for plaque to accumulate, contributing to better long-term oral hygiene and a more confident smile.",
    f1: "Effective cleaning to eliminate plaque and tartar buildup.",
    f2: "Reduction of gum inflammation and prevention of gum disease.",
    f3: "Smoother and shinier teeth, contributing to a brighter smile.",
    section1: "Benefits of Scaling and Polishing",
    section1Content: [
      "Prevents the buildup of plaque and tartar that can lead to gum disease.",
      "Improves oral hygiene and reduces the risk of cavities and bad breath.",
      "Enhances the appearance of teeth by removing surface stains and discoloration.",
      "Promotes a healthier and brighter smile through regular maintenance.",
    ],
    section2: "What to Expect During a Scaling and Polishing Session",
    section2Content: [
      "Initial examination of your oral health to determine the extent of cleaning needed.",
      "Scaling procedure to remove plaque and tartar using specialized tools.",
      "Polishing of teeth to achieve a smooth and shiny finish.",
      "Post-treatment advice on maintaining oral hygiene and scheduling follow-up visits.",
    ],
    section3: "Maintaining Oral Health After Scaling and Polishing",
    section3Content: [
      "Adopt a regular brushing and flossing routine to keep teeth clean.",
      "Use fluoride toothpaste to strengthen enamel and prevent cavities.",
      "Schedule regular dental check-ups and cleanings to maintain oral health.",
      "Avoid excessive consumption of staining foods and beverages to preserve teeth brightness.",
    ],
  };

  return (
    <div>
      <ServiceCard
        pagetitle={content.pagetitle}
        imgUrl={content.imgUrl}
        desc1={content.desc1}
        desc2={content.desc2}
        f1={content.f1}
        f2={content.f2}
        f3={content.f3}
        section1={content.section1}
        section1Content={content.section1Content}
        section2={content.section2}
        section2Content={content.section2Content}
        section3={content.section3}
        section3Content={content.section3Content}
      />
    </div>
  );
};

export default Scaling;