import React from "react";
import ServiceCard from "../ServicePages/ServiceCard";
import whitening from "../../Component/Pictures/whitening.webp";
const TeethWhitening = () => {
  const content = {
    pagetitle: "Teeth Whitening",
    imgUrl: whitening,
    desc1:
      "Teeth whitening is one of the premier services offered at Agrim Dental Home, established in 2066 BS. Our teeth whitening procedure is designed to remove stains and discoloration, giving you a brighter, more confident smile. Over time, teeth can become stained due to various factors such as coffee, tea, red wine, tobacco, and certain medications. At Agrim Dental Home, we use advanced whitening techniques and safe, effective whitening agents to ensure optimal results. Our experienced dental professionals are dedicated to providing personalized care, ensuring that each patient's whitening treatment is tailored to their specific needs and desired outcomes.",
    desc2:
      "In addition to enhancing the aesthetic appeal of your smile, teeth whitening can also boost your self-esteem and confidence. A whiter smile can make a significant difference in both personal and professional interactions. At Agrim Dental Home, we prioritize patient comfort and satisfaction, offering a relaxing and welcoming environment for all our treatments. Our commitment to using the latest technology and adhering to the highest standards of dental care ensures that you receive the best possible results from your teeth whitening procedure. Trust Agrim Dental Home to help you achieve a radiant smile that leaves a lasting impression",
    f1: "Advanced whitening technology for optimal results.",
    f2: "Personalized treatment plans for each patient.",
    f3: "Safe, comfortable, and welcoming environment.",
  };
  return (
    <div>
      <ServiceCard
        pagetitle={content.pagetitle}
        imgUrl={content.imgUrl}
        desc1={content.desc1}
        desc2={content.desc2}
        f1={content.f1}
        f2={content.f2}
        f3={content.f3}
      />{" "}
    </div>
  );
};

export default TeethWhitening;
