import React from "react";
import ServiceCard from "./ServiceCard";
import oral from "../../Component/Pictures/oral.webp";

const OralCheckup = () => {
  const content = {
    pagetitle: "Oral Checkup",
    imgUrl: oral,
    desc1:
      "During an oral checkup, your dentist will thoroughly examine your teeth, gums, and mouth to ensure optimal dental health. The examination typically starts with a visual inspection, where the dentist looks for any signs of decay, cavities, or gum disease. They may also use specialized tools like a dental mirror and probe to assess the condition of your teeth and gums more closely. Additionally, X-rays may be taken to detect any issues that are not visible to the naked eye, such as hidden cavities or problems with the roots of your teeth. After the examination, your dentist will discuss their findings with you and recommend any necessary treatments or preventive measures to maintain your oral health.",
    desc2:
      "Following the examination, your dentist will often perform a professional cleaning to remove plaque and tartar buildup from your teeth and gums. This process involves scaling, where the dentist uses special tools to scrape away hardened plaque and tartar from the surfaces of your teeth. They may also polish your teeth to remove surface stains and make them shine. Additionally, your dentist may provide guidance on proper oral hygiene practices, such as brushing and flossing techniques, and offer advice on diet and lifestyle habits that can affect your dental health. By attending regular oral checkups and cleanings, you can help prevent dental problems and maintain a healthy, beautiful smile for years to come.",
    f1: "Thorough assessment of oral health.",
    f2: "Identification of potential issues before they become serious problems.",
    f3: "Customized oral hygiene tips and treatment recommendations based on individual needs.",
    section1: "Why Are Regular Oral Checkups Important?",
    section1Content: [
      "Early detection of dental problems, reducing the risk of complications.",
      "Prevention of tooth decay and gum disease through professional cleaning.",
      "Maintenance of overall oral health and well-being.",
      "Opportunity to discuss any concerns or symptoms with your dentist.",
    ],
    section2: "What Happens During an Oral Checkup at Agrim Dental?",
    section2Content: [
      "Comprehensive visual and tactile examination of teeth and gums.",
      "Use of X-rays to detect hidden dental issues.",
      "Detailed discussion of findings and recommended treatments.",
      "Professional cleaning to remove plaque, tartar, and surface stains.",
    ],
    section3: "Why Choose Agrim Dental for Your Oral Checkup?",
    section3Content: [
      "Experienced dental professionals providing personalized care.",
      "State-of-the-art equipment for accurate diagnosis and treatment.",
      "Focus on patient education and preventive care.",
      "Comfortable and welcoming environment.",
    ],
  };

  return (
    <div>
      <ServiceCard
        pagetitle={content.pagetitle}
        imgUrl={content.imgUrl}
        desc1={content.desc1}
        desc2={content.desc2}
        f1={content.f1}
        f2={content.f2}
        f3={content.f3}
        section1={content.section1}
        section1Content={content.section1Content}
        section2={content.section2}
        section2Content={content.section2Content}
        section3={content.section3}
        section3Content={content.section3Content}
      />
    </div>
  );
};

export default OralCheckup;
