import React from "react";
import TopBar from "../../Component/TopBar";
import { Link } from "react-router-dom";

const ServiceCard = ({
  pagetitle,
  imgUrl,
  desc1,
  desc2,
  f1,
  f2,
  f3,
  section1,
  section1Content,
  section2,
  section2Content,
  section3,
  section3Content,
}) => {
  return (
    <div>
      <TopBar pageTitle={pagetitle} />
      <div className="flex flex-col lg:flex-row w-[90%] lg:w-[80%] mx-auto gap-10">
        {/* Appointment section */}
        <div
          className="lg:w-[30%] px-5 flex flex-col gap-8 shadow-sm shadow-slate-300 h-fit pb-2 rounded-md"
          data-aos="fade-down"
          data-aos-duration="800"
        >
          <p className="text-2xl lg:text-3xl font-semibold heading-font">
            Opening Hours
          </p>
          <div className="flex justify-between md:text-lg border-b-[0.5px] border-b-slate-500 pb-2 border-opacity-45">
            <p>Sunday to Saturday</p>
            <p>8:00 AM-7:30PM</p>
          </div>
          <Link to="/appointment" className="button text-center">
            Book Appointment
          </Link>
        </div>

        {/* Service-detail section */}
        <div
          className="lg:w-[70%] flex flex-col gap-[2rem]"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          <img
            src={imgUrl}
            alt={pagetitle}
            className="w-full rounded-md md:h-[30rem]"
          />
          <div
            className="flex flex-col gap-2 text-lg text-slate-700"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <div className="text-2xl md:text-2xl lg:text-3xl font-semibold text-black heading-font">
              {pagetitle}
            </div>
            <p className="text-sm md:text-lg">{desc1}</p>
            <p className="text-sm md:text-lg">{desc2}</p>
            <div>
              <p className="text-xl md:text-2xl font-semibold text-black mt-5 mb-2 heading-font">
                Key Features:
              </p>
              <ul className="list-disc ml-[2rem] text-sm md:text-lg">
                <li>{f1}</li>
                <li>{f2}</li>
                <li>{f3}</li>
              </ul>
            </div>

            {/* Additional sections */}
            <div className="mt-6" data-aos="fade-left">
              <h3 className="text-xl md:text-2xl font-semibold text-black mt-5 mb-2 heading-font">
                {section1}
              </h3>
              <ul className="list-disc ml-[2rem] text-sm md:text-lg">
                {section1Content.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>
            <div className="mt-6" data-aos="fade-right">
              <h3 className="text-xl md:text-2xl font-semibold text-black mt-5 mb-2 heading-font">
                {section2}
              </h3>
              <ul className="list-disc ml-[2rem] text-sm md:text-lg">
                {section2Content.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>
            <div className="mt-6" data-aos="fade-up">
              <h3 className="text-xl md:text-2xl font-semibold text-black mt-5 mb-2 heading-font">
                {section3}
              </h3>
              <ul className="list-disc ml-[2rem] text-sm md:text-lg">
                {section3Content.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
