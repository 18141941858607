import React from "react";
import ServiceCard from "./ServiceCard";
import rct from "../../Component/Pictures/rct.webp";

const Rct = () => {
  const content = {
    pagetitle: "Root Canal Treatment",
    imgUrl: rct,
    desc1:
      "Root Canal Treatment (RCT) is a crucial dental procedure aimed at salvaging teeth afflicted with severe infection or decay. When the inner pulp of a tooth becomes infected due to deep decay or trauma, it can lead to excruciating pain and potential tooth loss if left untreated. During RCT, the dentist carefully removes the infected pulp, thoroughly cleanses the interior of the tooth, and then seals it with a biocompatible material to prevent further infection. This meticulous process not only alleviates pain but also preserves the natural tooth structure, enabling patients to maintain proper chewing function and aesthetics.",
    desc2:
      "While the reputation of root canals might evoke anxiety in some patients, modern advancements in dental technology and anesthesia have made the procedure relatively comfortable and highly effective. By undergoing RCT, patients can avoid the need for extraction and subsequent tooth replacement options, such as dental implants or bridges, which can be more invasive and costly. Ultimately, Root Canal Treatment offers a conservative yet impactful solution for preserving dental health and promoting overall well-being.",
    f1: "Elimination of infection and pain from inside the tooth.",
    f2: "Saving the natural tooth and avoiding extraction.",
    f3: "Use of modern techniques to ensure comfort and effectiveness.",
    section1: "Why Root Canal Treatment?",
    section1Content: [
      "Relieves severe tooth pain caused by infection or decay.",
      "Prevents the spread of infection to surrounding teeth.",
      "Preserves the natural tooth structure and appearance.",
      "Avoids the need for more invasive procedures like extractions.",
    ],
    section2: "What to Expect During Root Canal Treatment",
    section2Content: [
      "Thorough examination and X-rays to assess the extent of infection.",
      "Local anesthesia to ensure a comfortable experience.",
      "Removal of infected pulp and cleaning of the tooth's interior.",
      "Filling and sealing of the tooth to prevent further infection.",
    ],
    section3: "Post-Treatment Care and Benefits",
    section3Content: [
      "Mild discomfort may be experienced post-procedure.",
      "Follow-up care to monitor healing and prevent complications.",
      "Restoration with a crown for added strength and protection.",
      "Long-term maintenance of dental health and natural tooth functionality.",
    ],
  };

  return (
    <div>
      <ServiceCard
        pagetitle={content.pagetitle}
        imgUrl={content.imgUrl}
        desc1={content.desc1}
        desc2={content.desc2}
        f1={content.f1}
        f2={content.f2}
        f3={content.f3}
        section1={content.section1}
        section1Content={content.section1Content}
        section2={content.section2}
        section2Content={content.section2Content}
        section3={content.section3}
        section3Content={content.section3Content}
      />
    </div>
  );
};

export default Rct;
