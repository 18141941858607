import React from "react";
import Slider from "react-slick";
import img1 from "./Pictures/Gallery/gallery3.jpg";
import img2 from "./Pictures/Gallery/gallery4.jpg";
import img3 from "./Pictures/Gallery/gallery5.jpg";
import img4 from "./Pictures/Gallery/gallery7.jpg";

const OurPatients = () => {
  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1024, // Breakpoint for larger devices
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600, // Breakpoint for smaller devices
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const patients = [
    {
      description:
        "I had a great experience at Agrim Dental Home. The staff is very friendly and professional.",
      image: img1,
      name: "Gita Bista",
    },
    {
      description:
        "The clinic is clean and modern. I felt very comfortable during my treatment.",
      image: img2,
      name: "Loki Chaulagain",
    },
    {
      description:
        "Dr. Sharma is an excellent dentist. He explained everything clearly and took great care of my teeth.",
      image: img3,
      name: "Amit Paudel",
    },
    {
      description:
        "I highly recommend Agrim Dental Home. They provide top-notch dental care.",
      image: img4,
      name: "Rajan Shahi",
    },
  ];

  return (
    <div className="my-8">
      <h2 className=" md:text-3xl text-2xl font-semibold text-center mb-6 ">
        Our Patients
      </h2>
      <Slider {...settings} className=" md:w-[80%] mx-auto">
        {patients.map((patient, index) => (
          <div key={index} className="px-4">
            <div className="bg-white p-6 rounded-lg text-center mx-auto">
              <p className=" text-lg text-gray-600 mb-4">
                {patient.description}
              </p>
              <img
                src={patient.image}
                alt={patient.name}
                className="w-[9rem] h-[9rem] object-cover rounded-full mx-auto mb-4"
              />
              <h3 className="text-lg font-semibold heading-font">
                {patient.name}
              </h3>
              <p className="text-gray-500">Agrim Dental Home Patient</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default OurPatients;
