import React from "react";
import ServiceCard from "./ServiceCard";
import restoration from "../../Component/Pictures/restoration.webp";

const Restoration = () => {
  const content = {
    pagetitle: "Restoration",
    imgUrl: restoration,
    desc1:
      "In the realm of dental health, a Restoration refers to the meticulous process of repairing and renewing damaged or decayed teeth to restore both function and aesthetics. Utilizing advanced techniques and materials, skilled dental professionals embark on a journey to revitalize smiles and enhance oral well-being. Through careful assessment and personalized treatment plans, each Restoration endeavor aims to rejuvenate the natural beauty and functionality of teeth, ensuring patients regain confidence in their smiles and overall dental health.",
    desc2:
      "From the precise shaping of dental prosthetics to the artful application of modern bonding agents, every Restoration procedure embodies the marriage of science and artistry. With a focus on preserving as much natural tooth structure as possible, dental Restorations not only address immediate concerns but also promote long-term oral health. As patients witness the transformation of their smiles, they embark on a journey of dental rejuvenation, experiencing the joy of renewed confidence and the freedom to laugh, speak, and eat without hesitation.",
    f1: "Use of durable and aesthetically pleasing materials for fillings and repairs.",
    f2: "Restorations that blend seamlessly with natural teeth.",
    f3: "Long-lasting solutions to maintain dental function and appearance.",
    section1: "Types of Restoration Procedures",
    section1Content: [
      "Dental Fillings: Repairing cavities with materials that match natural tooth color.",
      "Crowns: Placing caps over damaged teeth to restore strength and appearance.",
      "Bridges: Replacing missing teeth with artificial ones supported by adjacent teeth.",
      "Veneers: Applying thin shells to the front of teeth to improve appearance.",
    ],
    section2: "Benefits of Dental Restoration",
    section2Content: [
      "Restores the function of damaged or decayed teeth.",
      "Enhances the appearance of teeth, boosting confidence.",
      "Prevents further decay and damage by sealing affected areas.",
      "Improves overall dental health and oral hygiene.",
    ],
    section3: "What to Expect During and After Restoration",
    section3Content: [
      "Initial consultation to assess the condition of your teeth and discuss treatment options.",
      "Procedure performed with local anesthesia to ensure comfort.",
      "Post-treatment care instructions to maintain and protect restored teeth.",
      "Regular follow-up visits to ensure the success and longevity of the restoration.",
    ],
  };

  return (
    <div>
      <ServiceCard
        pagetitle={content.pagetitle}
        imgUrl={content.imgUrl}
        desc1={content.desc1}
        desc2={content.desc2}
        f1={content.f1}
        f2={content.f2}
        f3={content.f3}
        section1={content.section1}
        section1Content={content.section1Content}
        section2={content.section2}
        section2Content={content.section2Content}
        section3={content.section3}
        section3Content={content.section3Content}
      />
    </div>
  );
};

export default Restoration;
