import React from "react";
import { useForm } from "react-hook-form";
import { toast, Toaster } from "react-hot-toast";

const AppointmentForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    // Handle form submission here

    // Show success message
    toast.success("Thank you for book an appointment!");

    // Reset the form fields
    reset();
  };

  return (
    <div className="mx-auto p-8 bg-white rounded-md shadow-md shadow-slate-300 w-[86%] lg:w-[65%]">
      <h2 className="text-xl md:text-2xl font-semibold mb-5 text-center  heading-font">
        Book <span className=" text-yellow-600">An</span>  Appointment
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
          <div className="w-full">
            <label
              className="block mb-2 text-gray-700 heading-font"
              htmlFor="name"
            >
              Name
            </label>
            <input
              {...register("name", { required: "Name is required" })}
              type="text"
              id="name"
              placeholder="Name"
              className="w-full p-2 border border-gray-300 rounded"
            />
            {errors.name && (
              <p className="text-red-600 text-sm">{errors.name.message}</p>
            )}
          </div>
          <div className="w-full">
            <label
              className="block mb-2 heading-font text-gray-700"
              htmlFor="treatment"
            >
              Describe Your Dental Problems
            </label>
            <input
              {...register("treatment", {
                required: "Treatment or Problem is required",
              })}
              type="text"
              id="treatment"
              placeholder="Enter Treatment or Problem"
              className="w-full p-2 border border-gray-300 rounded"
            />
            {errors.treatment && (
              <p className="text-red-600 text-sm">{errors.treatment.message}</p>
            )}
          </div>
          <div className="w-full">
            <label
              className="block mb-2 heading-font text-gray-700"
              htmlFor="number"
            >
              Phone Number
            </label>
            <input
              {...register("number", {
                required: "Number is required",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Invalid phone number",
                },
              })}
              type="number"
              id="number"
              placeholder="Phone number"
              className="w-full p-2 border border-gray-300 rounded"
            />
            {errors.number && (
              <p className="text-red-600 text-sm">{errors.number.message}</p>
            )}
          </div>
        </div>
        <div className="w-full flex justify-center items-center my-4 mt-10">
          <button type="submit" className="button w-full md:w-[8rem]">
            Submit
          </button>
        </div>
      </form>
      <Toaster />
    </div>
  );
};

export default AppointmentForm;
