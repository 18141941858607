import React from "react";
import CountUp from "react-countup";

import { PiToothFill } from "react-icons/pi";
import { FaUserDoctor } from "react-icons/fa6";
import { FaUserInjured } from "react-icons/fa";
import { useInView } from "react-intersection-observer";

const CounterUp = () => {
  const { ref: doctorRef, inView: doctorInView } = useInView({
    triggerOnce: true,
  });

  const { ref: patientRef, inView: patientInView } = useInView({
    triggerOnce: true,
  });
  const { ref: cavityRef, inView: cavityInView } = useInView({
    triggerOnce: true,
  });

  return (
    <div className="bg-[#0dadf4] text-white py-16">
      <div className=" flex flex-col gap-14 lg:flex-row md:justify-between  w-[80%] mx-auto text-center">
        <div
          ref={doctorRef}
          className=" flex flex-col gap-2 lg:gap-3 items-center"
        >
          <FaUserDoctor className=" text-2xl md:text-2xl: lg:text-5xl" />
          {doctorInView && (
            <CountUp
              start={0}
              end={6}
              duration={3}
              className=" text-xl md:text-2xl lg:text-4xl font-bold"
            />
          )}
          <p className=" text-xl font-semibold">Professional Dentists</p>
        </div>

        <div
          ref={patientRef}
          className=" flex flex-col gap-2 lg:gap-3 items-center"
        >
          <FaUserInjured className=" text-2xl lg:text-5xl" />
          {patientInView && (
            <div>
              <CountUp
                start={0}
                end={14000}
                duration={3}
                className=" text-xl lg:text-4xl font-bold"
              />{" "}
              <span className=" text-xl lg:text-4xlfont-semibold">+</span>
            </div>
          )}
          <p className=" text-xl font-semibold">Happy Patients</p>
        </div>
        <div
          ref={cavityRef}
          className=" flex flex-col gap-2 lg:gap-3 items-center"
        >
          <PiToothFill className=" text-2xl lg:text-5xl" />
          {cavityInView && (
            <div>
              <CountUp
                start={0}
                end={15}
                duration={3}
                className=" text-xl lg:text-4xl font-bold"
              />{" "}
              <span className=" text-xl lg:text-4xl font-semibold">+</span>
            </div>
          )}
          <p className=" text-xl lg:text-xl font-semibold">Years Experience</p>
        </div>
      </div>
    </div>
  );
};

export default CounterUp;
